import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useDelayedNavigate } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import useStore from 'hooks/useStore'
import HomeIcon from 'images/home.svg'
import ActivitiesIcon from 'images/activities.svg'
import CalendarIcon from 'images/calendar.svg'
import InstructorsIcon from 'images/instructors.svg'
import ReviewsIcon from 'images/reviews.svg'

const Navigation = () => {
  const {
    userInfo = {},
    cardChanged,
    setAlertVisible
  } = useStore(({ userInfo, cardChanged, setAlertVisible }) => ({
    userInfo,
    cardChanged,
    setAlertVisible
  }))
  const { locations, contact, description } = userInfo

  const navigate = useDelayedNavigate()

  const serviceProviderInfoComplete = [locations, contact, description].every(Boolean)
  const links = useMemo(
    () =>
      linkManifest.map(({ to, Icon, iconClassName, title }) => (
        <NavLink
          key={to}
          className={({ isActive }) =>
            cx(style.item, { [style.disabledItem]: !serviceProviderInfoComplete, [style.activeItem]: isActive })
          }
          to={to}
          end
          onClick={event => {
            event.preventDefault()
            cardChanged ? setAlertVisible(true) : navigate(to)
          }}
        >
          <Icon className={cx(style.icon, iconClassName)} />

          <span className={style.title}>{title}</span>
        </NavLink>
      )),
    [linkManifest, userInfo, cardChanged]
  )

  return (
    <nav className={style.wrapper}>
      <NavLink
        className={({ isActive }) => cx(style.item, { [style.activeItem]: isActive })}
        to="/"
        end
        onClick={event => {
          event.preventDefault()
          navigate('/')
        }}
      >
        <HomeIcon className={style.icon} />

        <span className={style.title}>Home</span>
      </NavLink>

      {links}
    </nav>
  )
}

const style = {
  wrapper: css`
    z-index: 1;
    position: fixed;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 108px;
    height: calc(100vh - 60px);
    padding-top: 30px;
    box-shadow: 3px 0px 6px 0px #00000029;
    overflow: auto;
    background-color: white;

    @media ${MOBILE_VIEWPORT} {
      top: unset;
      bottom: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100vw;
      height: auto;
      padding: 10px 30px;
    }
  `,
  item: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    border-right: 5px solid transparent;
    color: #b8b8b8;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.2s;

    @media ${MOBILE_VIEWPORT} {
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 0;
      border: 0;
    }
  `,
  disabledItem: css`
    opacity: 0.5;
    pointer-events: none;
  `,
  activeItem: css`
    border-right: 5px solid #8591ff;
    color: #8591ff;
    cursor: default;

    svg {
      fill: #8591ff;
    }

    @media ${MOBILE_VIEWPORT} {
      border: 0;
    }
  `,
  icon: css`
    width: 40px;
    height: 40px;
    margin-bottom: 15px;

    @media ${MOBILE_VIEWPORT} {
      width: 20px;
      height: 20px;
      margin: 0;
    }
  `,
  instructorsIcon: css`
    width: 50px;

    @media ${MOBILE_VIEWPORT} {
      width: 30px;
    }
  `,
  title: css`
    @media ${MOBILE_VIEWPORT} {
      display: none;
    }
  `
}

const linkManifest = [
  { to: 'activities', Icon: ActivitiesIcon, title: 'Activities' },
  { to: 'calendar', Icon: CalendarIcon, title: 'Calendar' },
  { to: 'instructors', Icon: InstructorsIcon, iconClassName: style.instructorsIcon, title: 'Instructors' },
  { to: 'reviews', Icon: ReviewsIcon, title: 'Reviews & Ratings' }
]

export default Navigation
