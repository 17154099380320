import React from 'react'
import facebookIcon from 'images/facebook.png'
import useFacebookSDK from '../../services/facebookSDK/useFacebookSDK'
import { axios } from 'frontend-essentials'
import { facebookLogin } from 'utils/endpoints.constants'
import useStore from 'hooks/useStore'
import { setUserFromResponse } from '../../utils/helpers/auth.helpers'

type TFacebookResponse = {
  accessToken: string
  userID: string
  expiresIn: number
  signedRequest: string
  graphDomain: string
  data_access_expiration_time: number
}

interface Props {
  style: {
    oAuth: any
    oAuthText: any
  }
}

const FacebookSignIn: React.FC<Props> = ({ style }) => {
  const { login } = useFacebookSDK()
  const { setUser } = useStore(state => state)

  const handleLoginFacebook = async () => {
    const fbResponse: TFacebookResponse = await login()
    const data = await axios.post(facebookLogin, {
      accessToken: fbResponse.accessToken,
      userID: fbResponse.userID
    })
    setUserFromResponse(data, setUser)
  }
  return (
    <button className={style.oAuth} onClick={handleLoginFacebook}>
      <img width="12.5px" src={facebookIcon} alt="facebook" />
      <span className={style.oAuthText}>Sign in with Facebook</span>
    </button>
  )
}

export default FacebookSignIn
