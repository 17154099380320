import { useState, useEffect, memo } from 'react'
import { axios, useAxios } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import useChanged from 'hooks/useChanged'
import Card from 'components/common/Card'
import Input from 'components/common/Input'
import Checkbox from 'components/common/Checkbox'
import LocationIcon from 'images/location.svg'
import RemoveIcon from 'images/remove.svg'
import useStore from 'hooks/useStore'
import Alert from 'components/common/Alert'
import { Typography } from '@mui/material'
import { set } from 'date-fns'

const defaultLocation = { name: 'Main', city: '', streetAddress: '' }
const defaultLocations = [defaultLocation]

const Address = ({
  className,
  userExpanded,
  saving,
  locations: initialLocations = defaultLocations,
  expand,
  collapse,
  setCardChanged,
  updateProfile
}) => {

  const [alertTypography, setAlertTypography] = useState(false)


  const [locations, setLocations] = useState(initialLocations)
  const [multipleLocations, setMultipleLocations] = useState(locations.length > 1)

  const changed = useChanged([[locations, initialLocations]])

  const expanded = userExpanded || initialLocations === defaultLocations

  useEffect(() => setCardChanged(changed), [changed])

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        axios
          .get('location', { params: { lat: latitude, lng: longitude } })
          .then(({ data }) => {
            console.log('data.address_components', data.address_components)
            const city = data.address_components.find(({ types }) => types.includes('locality'))?.long_name
            const streetAddressComponents = data.address_components
              .filter(({ types }) => types.includes('route'))
              .map(component => component.long_name.replace('Street', '').trim())
            const streetAddress = streetAddressComponents.join(' ')
            const number = data.address_components.find(({ types }) => types.includes('street_number'))?.long_name
            // необходима обработка случая, когда в адресе нет улицы, а есть название района
            // const district = data.district_components.find(({ types }) => types.includes('sublocality'))?.long_name;

            // Проверка на `undefined`
            if (city !== undefined && streetAddress !== undefined) {
              let fullAddress = streetAddress
              if (number !== undefined) {
                fullAddress = `${streetAddress} ${number}`
              }

              const updatedLocations = [
                {
                  city,
                  streetAddress: fullAddress,
                  houseNumber: number,
                  point: { lat: latitude.toString(), lng: longitude.toString() }
                },
                ...locations.slice(1)
              ]

              setLocations(updatedLocations)
            } else {
              // Обработка случая, когда значения `city` или `streetAddress` равны `undefined`
              throw new Error('Failed to get full address')
            }
          })
          .catch(err => console.log(err))
      },
      undefined,
      { enableHighAccuracy: true }
    )
  }

  const setLocationsProperty = (index, property) => {
    console.log('property', property)
    setLocations(locations.map((location, ind) => (ind === index ? { ...location, ...property } : location)))
  }

  const removeLocation = indexToRemove => {
    const filteredLocations = locations.filter((_, index) => index !== indexToRemove)

    setLocations(filteredLocations)

    if (filteredLocations.length < 2) setMultipleLocations(false)
  }

  const onSave = async () => {
    const response = await axios.get('/validate_address', {
      params: {
        address: `${locations[0].city} ${locations[0].streetAddress} ${locations[0].houseNumber}`
      }
    })
    if (response.data.response.result === 'OK') {
      updateProfile({
        data: {
          locations: locations
            .slice(0, multipleLocations ? undefined : 1)
            .map(({ name, city, streetAddress, point, houseNumber }, ind) => ({
              name: ind === 0 ? 'Main' : name,
              city,
              street_address: streetAddress,
              house_number: houseNumber,
              state: 'Israel',
              country: 'Israel',
              point
            }))
        }
      })
      setAlertTypography(false)
    } else {
      setAlertTypography(true)
    }
  }

  const onDiscard = () => {
    setLocations(initialLocations)
    setMultipleLocations(initialLocations.length > 1)
    setCardChanged(false)
    setAlertTypography(false)
    collapse()
  }

  return (
    <Card
      className={className}
      Icon={LocationIcon}
      title="Address"
      expanded={expanded}
      changed={changed}
      saving={saving}
      onClick={expand}
      onSave={onSave}
      onDiscard={onDiscard}
    >
      <div className="flex-col w-full">
        {expanded ? (
          <div className="flex-col">
            <div className="flex-wrap">
              <Input
                className={style.addressSection}
                label="City"
                value={locations[0].city}
                required
                onChange={event => setLocationsProperty(0, { name: 'Main', city: event.target.value })}
              />

              <Input
                className={cx(style.addressSection, style.streetSection)}
                label="Street"
                value={locations[0].streetAddress}
                required
                onChange={event => setLocationsProperty(0, { name: 'Main', streetAddress: event.target.value })}
              />

              <Input
                className={cx(style.addressSection, style.streetSection)}
                label="House number"
                value={locations[0].houseNumber}
                required
                onChange={event => setLocationsProperty(0, { name: 'Main', houseNumber: event.target.value })}
              />
            </div>

            <button
              className={cx(style.addLocation, style.findLocation)}
              type="button"
              //disabled={gettingLocation}
              onClick={getCurrentLocation}
            >
              Find my location
            </button>

            <Checkbox
              className={style.multipleLocations}
              labelClassName={style.multipleLocationsTitle}
              label="Do you have multiple rooms or more than one location where you conduct activities? If so, add them
                below."
              checked={multipleLocations}
              disabled={
                !locations[0].city || !locations[0].streetAddress || !!Object.values(locations[1] || {}).join('')
              }
              onChange={checked => {
                setMultipleLocations(checked)
                if (locations.length === 1) setLocations([locations[0], { ...defaultLocation, name: '' }])
              }}
            />

            {multipleLocations && (
              <div className={style.additionalLocations}>
                {locations.slice(1)?.map(({ name, city, streetAddress }, ind) => (
                  <div className={style.additionalLocation} key={ind}>
                    <Input
                      className={style.addressSection}
                      label="Location name"
                      value={name}
                      required
                      onChange={event => setLocationsProperty(ind + 1, { name: event.target.value })}
                    />

                    <Input
                      className={style.addressSection}
                      label="City"
                      value={city}
                      required
                      onChange={event => setLocationsProperty(ind + 1, { city: event.target.value })}
                    />

                    <Input
                      className={cx(style.addressSection, style.streetSection)}
                      label="Street"
                      value={streetAddress}
                      required
                      onChange={event => setLocationsProperty(ind + 1, { streetAddress: event.target.value })}
                    />

                    <button className={style.remove} type="button" onClick={() => removeLocation(ind + 1)}>
                      <RemoveIcon width="15px" />
                      <span className={style.removeText}>Remove location</span>
                    </button>
                  </div>
                ))}

                <button
                  className={style.addLocation}
                  type="button"
                  disabled={Object.values(locations[locations.length - 1]).some(value => !value)}
                  onClick={() => {
                    if (locations[locations.length - 1].name) {
                      setLocations([...locations, { ...defaultLocation, name: '' }])
                    }
                  }}
                >
                  + Add another location
                </button>
              </div>
            )}
          </div>
        ) : (
          <span>
            {locations[0]?.streetAddress}, {locations[0]?.houseNumber} , {locations[0]?.city}
          </span>
        )}
      </div>
      {alertTypography && (
         <Typography className={style.alertTypography} variant="body2" color="error">
          Please enter a valid address
        </Typography>
      )  
      }
      
    </Card>
    
  )
}

const style = {
  addressSection: css`
    width: 100%;
    max-width: 250px;
    margin: 0 10px 5px 0;

    @media ${MOBILE_VIEWPORT} {
      max-width: unset;
      margin-right: 0;
    }
  `,
  streetSection: css`
    max-width: 400px;
  `,
  addLocation: css`
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    color: #5663ff;

    :disabled {
      opacity: 0.75;
      cursor: default;
    }
  `,
  findLocation: css`
    margin-top: 10px;

    :disabled {
      opacity: 0.75;
      cursor: default;
    }
  `,
  multipleLocations: css`
    display: flex;
    align-items: center;
    margin: 20px 0 0 -3px;
  `,
  multipleLocationsTitle: css`
    font-size: 18px;
  `,
  additionalLocations: css`
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  `,
  additionalLocation: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @media ${MOBILE_VIEWPORT} {
      :not(:first-child) {
        margin-top: 20px;
      }
    }
  `,
  remove: css`
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 18px;

    :hover {
      opacity: 0.75;
    }

    @media ${MOBILE_VIEWPORT} {
      margin-top: 5px;
    }
  `,
  removeText: css`
    margin-left: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #ee2a2a;
  `
}

export default memo(Address)
