import { useState, useEffect, memo } from 'react'
import { Media } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import useChanged from 'hooks/useChanged'
import Card from 'components/common/Card'
import Input from 'components/common/Input'
import Switch from 'components/common/Switch'
import SocialIcon from 'images/social.svg'

const defaultIntegration = { businessAccount: '', shareActive: false }
const defaultIntegrations = [defaultIntegration]

const Social = ({
  className,
  userExpanded,
  saving,
  socialIntegrations: initialIntegrations = defaultIntegrations,
  expand,
  collapse,
  setCardChanged,
  updateProfile
}) => {
  const [integrations, setIntegrations] = useState(initialIntegrations)

  const changed = useChanged([[integrations, initialIntegrations]])

  const expanded = userExpanded || initialIntegrations === defaultIntegrations

  useEffect(() => setCardChanged(changed), [changed])

  const onDiscard = () => {
    setIntegrations(initialIntegrations)
    setCardChanged(false)
    collapse()
  }

  return (
    <Card
      className={className}
      Icon={SocialIcon}
      title="Social integrations"
      expanded={expanded}
      changed={changed}
      saving={saving}
      onClick={expand}
      onSave={() =>
        updateProfile({
          data: {
            social_integrations: integrations.map(({ businessAccount, shareActive }) => ({
              business_account: businessAccount,
              share_active: shareActive
            }))
          }
        })
      }
      onDiscard={onDiscard}
    >
      {integrations.map(({ businessAccount, shareActive }, index) =>
        expanded ? (
          <div key={index} className={style.integration}>
            <Input
              className={style.input}
              type="url"
              label="Business account"
              value={businessAccount}
              required
              onChange={event =>
                setIntegrations(
                  integrations.map((integration, ind) =>
                    index === ind ? { ...integration, businessAccount: event.target.value } : integration
                  )
                )
              }
            />

            <div className={style.sharing}>
              <Media query={DESKTOP_VIEWPORT}>
                <p className={style.sharingDescription}>
                  Link and share my Facebook/Instagram account stories to my business page on Velo
                </p>
              </Media>

              <Switch
                className={style.sharingSwitch}
                label={`Story sharing ${shareActive ? 'on' : 'off'}`}
                checked={shareActive}
                onChange={event =>
                  setIntegrations(
                    integrations.map((integration, ind) =>
                      index === ind ? { ...integration, shareActive: event.target.checked } : integration
                    )
                  )
                }
              />
            </div>
          </div>
        ) : (
          <div className="items-center" key={businessAccount}>
            <span>{businessAccount}</span>

            <Media query={DESKTOP_VIEWPORT}>
              <Switch
                className={cx(style.sharingSwitch, style.disabledSwitch)}
                label={`Story sharing ${shareActive ? 'on' : 'off'}`}
                checked={shareActive}
              />
            </Media>
          </div>
        )
      )}
    </Card>
  )
}

const style = {
  integration: css`
    margin-bottom: 15px;
  `,
  input: css`
    width: 400px;

    @media ${MOBILE_VIEWPORT} {
      width: 100%;
    }
  `,
  sharing: css`
    display: flex;
    align-items: center;
    margin-top: 4px;
  `,
  sharingDescription: css`
    font-size: 18px;
    font-weight: 400;
  `,
  sharingSwitch: css`
    margin-left: auto;
  `,
  disabledSwitch: css`
    opacity: 0.65;
    pointer-events: none;
  `
}

export default memo(Social)
