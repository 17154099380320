import { css, cx } from '@emotion/css'
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material'
import type { FC } from 'react'

export type ChipProps = MuiChipProps

const Chip: FC<ChipProps> = ({ className, ...otherProps }) => {
  return <MuiChip className={cx(style.wrapper, className)} {...otherProps} />
}

const style = {
  wrapper: css`
    height: auto;
    margin-right: 8px;
    padding: 2px 6px;
    border: 1px solid #5663ff;
    font-size: 14px;
    color: #5663ff;
    background-color: #e9ebff;
    cursor: pointer;
  `
}

export default Chip
