import { useState, createContext, FC, HTMLAttributes } from 'react'
import { css, cx } from '@emotion/css'
import { Menu as MuiMenu } from '@mui/material'

import OptionsIcon from 'images/options.svg'
import StatusIcon from 'images/status.svg'
import ArrowDownIcon from 'images/arrow-down.svg'

export const [OPTIONS, STATUS] = ['options', 'status']

export type MenuProps = HTMLAttributes<HTMLDivElement> & {
  variant?: string
  Icon?: any
  active?: boolean
}

const Menu: FC<MenuProps> = ({ className, variant = OPTIONS, Icon, active, children, ...otherProps }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  return (
    <div className={cx('relative', className)} {...otherProps}>
      {variant === STATUS ? (
        <button className={cx(style.status, { grayscale: !active })} onClick={event => setAnchor(event.currentTarget)}>
          <StatusIcon width="20px" />

          <span className={style.statusText}>{active ? 'Active' : 'Inactive'}</span>

          <ArrowDownIcon className={style.arrow} />
        </button>
      ) : (
        <button
          className={cx(style.options, { [style.defaultOptions]: !Icon })}
          onClick={event => setAnchor(event.currentTarget)}
        >
          {Icon ? <Icon /> : <OptionsIcon width="8px" />}
        </button>
      )}

      <MuiMenu
        className={cx(style.menu, { [style.wideMenu]: variant === STATUS })}
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {children}
      </MuiMenu>
    </div>
  )
}

const style = {
  options: css`
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      opacity: 0.75;
    }
  `,
  defaultOptions: css`
    width: 26px;
  `,
  status: css`
    display: flex;
    align-items: center;
    width: 130px;
    padding: 4.5px 10px;
    border: 1px solid #5663ff;
    border-radius: 100px;
    background-color: white;

    :hover {
      background-color: #eeeeee;
    }

    :active {
      background-color: #dcdcdc;
    }
  `,
  statusText: css`
    margin-left: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #3b48e7;
  `,
  arrow: css`
    width: 10px;
    margin: 4px 0 0 auto;
  `,
  menu: css`
    .MuiMenu-paper {
      margin-top: 10px;
      padding: 15px;
      border-radius: 20px;
      box-shadow: 0px 0px 4px 0px #00000040;
      color: black;
      transition-duration: 150ms !important;
    }

    .MuiMenu-list {
      padding: 0;
    }
  `,
  wideMenu: css`
    .MuiMenu-paper {
      min-width: 260px;
    }
  `
}

export default Menu
