import { css } from '@emotion/css'
import DatePicker from 'components/common/DatePicker'
import Select from 'components/common/Select'
import { SelectChangeEvent } from '@mui/material'
import useNamesOfActivityTypes from 'hooks/useNamesOfActivityTypes'
import React, { FC, useState } from 'react'
import { REPETITIONS } from 'utils/constans'
import { getScheduleTimes } from 'utils/helpers/schedule.helpers'
import UserIcon from 'images/userCircleBlack.svg'
import GroupIcon from 'images/groups.svg'
import LocationIcon from 'images/location2.svg'
import { NavLink } from 'react-router-dom'
import { path } from 'utils/endpoints.constants'
import { ActivityDrawerType } from 'hooks/useStore'

interface Props {
  activityDrawerType: ActivityDrawerType
  handleSetActivityDrawerType: (activityDrawerType: ActivityDrawerType) => void
  activityTypes: { label: string; value: number }[]
  types: number[]
  description: string
  style: {
    [key: string]: string
  }
  fromDate: Date
  toDate: Date
  setForm: (...values: any) => void
  repeats: number
  instructorName: string
  totalSpots: number
  userLocations: (id: number) => any
  locationID: number
  pathname: string
  mobile: boolean
}

const ActivityDrawerSecondary: FC<Props> = ({
  activityDrawerType,
  handleSetActivityDrawerType,
  activityTypes,
  description,
  types,
  style,
  fromDate,
  toDate,
  setForm,
  repeats,
  instructorName,
  totalSpots,
  userLocations,
  locationID,
  pathname,
  mobile
}) => {
  const activities = useNamesOfActivityTypes(types, style, activityTypes)
  const location = userLocations(locationID)

  return (
    <div className={style.main}>
      <p className={localStyle.activities}>
        {/* {activities.map((activityName, i) => (i === activities.length - 1 ? activityName : activityName + ', '))} */}
        {description}
      </p>
      <DatePicker
        className={style.date}
        // label="Activity occurs"
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={value => setForm({ fromDate: value })}
        onToDateChange={value => setForm({ toDate: value })}
      />
      <Select
        className={localStyle.repeats}
        label="Repeats"
        options={REPETITIONS.map((repetition, ind) => ({ label: repetition, value: ind }))}
        value={repeats}
        onChange={(event: SelectChangeEvent<unknown>) => setForm({ repeats: event.target.value as number })}
      />
      <div className={localStyle.infoWrapper}>
        <div className={localStyle.infoRow}>
          <UserIcon width={20} height={20} />
          <p>{instructorName}</p>
        </div>
        <div className={localStyle.infoRow}>
          <GroupIcon width={20} height={20} />
          <p>
            {totalSpots} participant{totalSpots > 1 ? 's' : ''}
          </p>
        </div>
        <div className={localStyle.infoRow}>
          <LocationIcon width={20} height={20} />
          <p>
            {/* REVIEW - What is "location room?" */}
            {location.city}, {location.streetAddress} {location.houseNumber}
          </p>
        </div>
      </div>
      {!mobile && pathname !== path.calendar && (
        <NavLink
          className={style.viewCalendar}
          to={path.calendar}
          onClick={() => {
            handleSetActivityDrawerType('calendar')
          }}
        >
          View calendar
        </NavLink>
      )}
    </div>
  )
}

export default ActivityDrawerSecondary

const localStyle = {
  activities: css`
    color: #000;
    font-size: 18px;
    font-weight: 500;
  `,
  repeats: css`
    margin-bottom: 15px;
  `,
  infoWrapper: css``,
  infoRow: css`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 8px;
    p {
      font-size: 15px;
    }
  `
}
