import { Button, FormControl, MenuItem, Select, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import Cropper, { Area, Point } from 'react-easy-crop'
import { ICroppedAreaPixels } from 'types/types'
import getCroppedImg from './CropImage'
import { ASPECT_RATIOS } from 'utils/constans'

const GlobalWrapper = styled('div')`
  position: relative;
  z-index: 1000;
`

const Wrapper = styled('div')`
  position: fixed;
  top: 120px;
  bottom: 120px;
  left: 50%;
  width: 80%;
  translate: -50% 0%;
  img {
    width: auto;
    height: auto;
  }
`

const ControlsWrapper = styled('div')`
  position: fixed;
  left: 50%;
  bottom: 10px;
  translate: -50% 0%;
  width: 150px;
`

const Backdrop = styled('div')`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ButtonWrapper = styled('div')`
  text-align: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MyButton = styled(Button)``

interface Props {
  imageId: any
  imageUrl: any
  cropInit: any
  zoomInit: any
  aspectInit: any
  onCancel: () => void
  setCroppedImageFor: (imageId: any, croppedImageUrl: any, crop: any, zoom: any, aspect: any) => void
  resetImage: (imageId: any) => void
}

const ImageCropDialog: React.FC<Props> = ({
  imageId,
  imageUrl,
  cropInit,
  zoomInit,
  aspectInit,
  onCancel,
  setCroppedImageFor,
  resetImage
}) => {
  if (zoomInit == null) {
    zoomInit = 1
  }
  if (cropInit == null) {
    cropInit = { x: 0, y: 0 }
  }
  if (aspectInit == null) {
    aspectInit = ASPECT_RATIOS[0]
  }

  const [zoom, setZoom] = useState(zoomInit)
  const [crop, setCrop] = useState<Point>(cropInit)
  const [aspect, setAspect] = useState(aspectInit)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICroppedAreaPixels | null | any>(null)

  const formatImageUrl = url => {
    if (!url.startsWith('data:image')) {
      console.log('formatImageUrl', url)
      return `data:image/png;base64, ${url}`
    }
    return url
  }

  const formattedImageUrl = formatImageUrl(imageUrl)
  console.log('formattedImageUrl', formattedImageUrl)

  const onCropChange = crop => {
    setCrop(crop)
  }

  const onZoomChange = zoom => {
    setZoom(zoom)
  }

  const onAspectChange = event => {
    const value = event.target.value
    const ratio = ASPECT_RATIOS.find(ratio => ratio.value === value)
    setAspect(ratio)
  }

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const onCrop = async () => {
    console.log(imageUrl)
    const croppedImageUrl = await getCroppedImg(formattedImageUrl, croppedAreaPixels)
    setCroppedImageFor(imageId, croppedImageUrl, crop, zoom, aspect)
  }

  const onResetImage = () => {
    resetImage(imageId)
  }

  return (
    <GlobalWrapper>
      <Backdrop></Backdrop>
      <Wrapper>
        <Cropper
          image={formattedImageUrl}
          zoom={zoom}
          crop={crop}
          aspect={aspect.value}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </Wrapper>
      <ControlsWrapper>
        <FormControl sx={{ minWidth: 120 }} fullWidth size="small">
          <Select sx={{ backgroundColor: 'white' }} value={aspect.value} onChange={onAspectChange}>
            {ASPECT_RATIOS.map(ratio => (
              <MenuItem key={ratio.label} value={ratio.value} selected={ratio.value === aspect.value}>
                {ratio.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonWrapper>
          <MyButton onClick={onCancel}>Cancel</MyButton>
          {/* <MyButton onClick={onResetImage} >Reset</MyButton> */}
          <MyButton onClick={onCrop}>Crop</MyButton>
        </ButtonWrapper>
      </ControlsWrapper>
    </GlobalWrapper>
  )
}

export default ImageCropDialog
