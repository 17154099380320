// <API endpoints> ===========================================================================================
export const registration = '/registration'
export const refresh = '/refresh'
export const login = '/login'
export const googleLogin = '/google_login'
export const facebookLogin = '/fb_login'
export const logout = '/logout'
export const activities = '/activities'
export const activity = '/activity'
export const uploadActivityImage = '/upload_activity_image'
export const activityImages = '/activity-images'
export const serviceProviderImages = '/profile-images'
export const employeeImages = '/employee-images'
export const serviceProvider = '/profile/service-provider'
// </API endpoints> ==========================================================================================

// <Other endpoints> ===========================================================================================
export const googleOauth = 'https://www.googleapis.com/oauth2/v1/userinfo'
// </Other endpoints> ==========================================================================================

// <Local App endpoints> ===========================================================================================
export const path = {
  activities: '/activities',
  calendar: '/calendar',
  instructors: '/instructors',
  reviews: '/reviews'
}
// </Local App endpoints> ==========================================================================================
