import { css } from '@emotion/css'
import styled from '@emotion/styled'
import React, { useState, ChangeEvent, FC } from 'react'

interface Props {
  title: string
  setTitle: (title: string) => void
  placeholder: string
  initHeight: number
  lineHeight: number
  style?: {
    fontSize: string
    width: string
    paddingLeft: string
    paddingRight: string
    [key: string]: string | number
  }
}

const MultilineInput: FC<Props> = ({ title, setTitle, placeholder, initHeight, lineHeight, style }) => {
  const [textareaHeight, setTextareaHeight] = useState<string>('auto')

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const element = e.target
    element.style.height = initHeight.toString() + 'px'
    element.style.height = element.scrollHeight + 'px'
    setTitle(e.target.value)
    setTextareaHeight(element.style.height)
  }

  return (
    <TextArea
      style={{ ...style }}
      lineHeight={lineHeight}
      initHeight={initHeight}
      value={title}
      onChange={handleTextChange}
      placeholder={placeholder}
    />
  )
}

export default MultilineInput

const TextArea = styled.textarea<{ lineHeight: number; initHeight: number }>`
  padding-top: ${({ lineHeight }) => `calc((37px - ${lineHeight}px) / 2)`};
  padding-bottom: ${({ lineHeight }) => `calc((37px - ${lineHeight}px) / 2)`};
  margin: 0;
  line-height: ${({ lineHeight }) => lineHeight}px;
  height: ${({ initHeight }) => initHeight}px;
  resize: none;
  overflow-y: hidden;
  &:active {
    border: none;
    outline: none;
  }
  &:focus {
    border: none;
    outline: none;
  }
`
