import { css, cx } from '@emotion/css'
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material'
import type { FC } from 'react'

export type RadioProps = MuiRadioProps & {
  onChange: (checked: boolean) => void
}

const Radio: FC<RadioProps> = ({ className, onChange, ...otherProps }) => {
  return (
    <MuiRadio className={cx(style.wrapper, className)} onChange={(_, checked) => onChange(checked)} {...otherProps} />
  )
}

const style = {
  wrapper: css`
    padding: 0;
    color: #3b48e7;

    &.Mui-checked {
      color: #3b48e7;
    }
  `
}

export default Radio
