export const PENALTY_TIMES = ['3 hours', '6 hours', '12 hours', '24 hours', '48 hours']
export const BOOKING_TIMES = ['30 minutes', '1 hour', '2 hours', '6 hours', '12 hours', '24 hours']
export const REPETITIONS = ['This activity does not repeat', 'Daily', 'Weekly', 'Monthly']
export const DEFAULT_BOOKING = { totalSpots: 1, bookingOptionId: 0 }
export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const API_GOOGLE_KEY = 'AIzaSyCLyuhjoIa0eN_yjYhYy82gQo2sBqTda0A'
export const IMAGE_EDIT_OPTIONS = [
  { label: 'Make cover image', value: 0 },
  { label: 'Crop image', value: 1 },
  { label: 'Replace image', value: 2 },
  { label: 'Delete image', value: 3 }
]
export const ASPECT_RATIOS = [
  { value: 1 / 1, label: 'Square' },
  { value: 4 / 3, label: 'Landscape' },
  { value: 3 / 4, label: 'Wide Portrait' },
  { value: 16 / 9, label: 'Widescreen' },
  { value: 9 / 16, label: 'Narrow Portrait' }
]
