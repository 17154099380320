import { useAxios, useMedia, useObjectState } from 'frontend-essentials'
import { css } from '@emotion/css'

import useStore, { ActivityDrawerPartial, UserStore, UserStoreState } from 'hooks/useStore'
import Button from 'components/common/Button'
import Activity from 'components/Activity'
import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import { Suspense, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import CalendarDrawer from 'components/CalendarComponents/CalendarDrawer'
import { set } from 'date-fns'
import { IActivity } from 'types/types'
import { getScheduleTimes } from 'utils/helpers/schedule.helpers'

interface Props {
  onOpen: () => void
}

const Activities: React.FC<Props> = () => {
  const setActivityDrawer = useCallback(
    useStore((state: UserStore) => state.setActivityDrawer),
    []
  )
  const activityDrawer = useStore((state: any) => state.activityDrawer)
  const userInfo = useStore((state: any) => state.userInfo)
  const nominalRerender = useStore((state: any) => state.nominalRerender)
  const { desktop } = useMedia({ desktop: DESKTOP_VIEWPORT })
  const user = useStore((state: any) => state.user)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedActivityID, setSelectedActivityID] = useState<number | null>(null)

  const selectedActivityIDRef = useRef(selectedActivityID)

  useEffect(() => {
    selectedActivityIDRef.current = selectedActivityID
  }, [selectedActivityID])

  const { data = {}, activate: getActivities } = useAxios({
    params: {
      user_id: user.userId,
      no_images_no_pagination: 0,
      page: currentPage
    },
    url: 'activities',
    method: 'get',
    uuid: 'activities',
    camelCased: true,
    onSuccess: ({ data: { response } }) => {
      console.log(response)
      setTotalPages(response.totalPages)
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  const handleOpenActivityDrawerEditor = (activity: IActivity, id: number) => {
    if (id !== selectedActivityIDRef.current || !activityDrawer.open) {
      setSelectedActivityID(id)
      setActivityDrawer({
        open: true,
        activityDrawerType: 'edit',
        id,
        title: activity?.title,
        //images
        image: activity?.mainImage,
        types: activity?.types,
        instructorName: activity?.instructorName,
        description: activity?.description,
        requirements: activity?.requirements,
        locationID: activity?.location.id,
        repeats: activity?.schedule?.recurring.id,
        fromDate: activity && getScheduleTimes(activity.schedule).fromDate,
        toDate: activity && getScheduleTimes(activity.schedule).toDate,
        booking: activity?.booking,
        cancellationPolicy: activity?.cancellationPolicy,
        onSave: getActivities
      })
    }
  }

  const handleOpenActivityDrawer = () => {
    setSelectedActivityID(null)
    setActivityDrawer({ open: true, activityDrawerType: 'create', onSave: getActivities })
  }

  const { response: { activities = [] } = {} } = useMemo(() => data, [data])
  useEffect(() => {
    getActivities({
      params: {
        user_id: user.userId,
        no_images_no_pagination: 0,
        page: currentPage
      }
    })
    setActivityDrawer({ open: false })
  }, [currentPage, getActivities, user.userId, nominalRerender])
  return (
    <>
      {activities ? (
        <div className={style.wrapper}>
          <div className={style.header}>
            <h1 className={style.title}>Activities</h1>

            <Button className={style.addActivity} onClick={handleOpenActivityDrawer}>
              + {desktop ? 'Create n' : 'N'}ew activity
            </Button>
          </div>

          <div className={style.activities}>
            {activities.map((activity: IActivity) => {
              return (
                <Activity
                  key={activity.id}
                  {...activity}
                  onSave={getActivities}
                  isSelected={activityDrawer.open && selectedActivityID === activity.id}
                  onClick={() => handleOpenActivityDrawerEditor(activity, activity.id)}
                />
              )
            })}
          </div>
          <div className={style.pagination}>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                variant="outlined"
                color="primary"
              />
            )}
          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

const style = {
  wrapper: css`
    @media ${MOBILE_VIEWPORT} {
      margin: 20px 15px;
    }
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media ${MOBILE_VIEWPORT} {
      margin-bottom: 15px;
    }
  `,
  title: css`
    font-size: 30px;
    font-weight: 700;
    @media ${MOBILE_VIEWPORT} {
      font-size: 24px;
    }
  `,
  addActivity: css`
    @media ${MOBILE_VIEWPORT} {
      padding: 8px 18px;
    }
  `,
  activities: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  pagination: css`
    display: flex;
    justify-content: center;
    margin-top: 20px;
  `,
  subtitle: css`
    margin-right: 20px;
  `
}

export default Activities
