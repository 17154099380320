import { Link } from 'react-router-dom'
import { axios, useDelayedNavigate } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import Button, { COMPACT } from 'components/common/Button'
import { Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { set } from 'date-fns'
import { useImageFetch } from 'hooks/useImageFetch'
import useStore from 'hooks/useStore'

const Photos = ({ className, imagesIDs }) => {
  const navigate = useDelayedNavigate()
  const [loadedImages, setLoadedImages] = useState([])
  const { serviceProviderImages, setServiceProviderImages } = useStore(state => state)

  const fetchImages = useCallback(async () => {
    const newServiceProviderImages = []
    for (const imageID of imagesIDs) {
      try {
        const response = await axios.get('image_body', {
          params: {
            image_id: imageID,
            object_type: 1
          }
        })

        setLoadedImages(loadedImages => [...loadedImages, response.data.response.image.image_body])
        newServiceProviderImages.push(response.data.response.image)

        console.log(response.data.response.image)
      } catch (e) {
        console.log(e)
      }
    }
    setServiceProviderImages(newServiceProviderImages)
  }, [imagesIDs])

  useEffect(() => {
    fetchImages()
  }, [imagesIDs])
  // useEffect(() => {}, [serviceProviderImages])

  return (
    <div className={cx(style.wrapper, className)}>
      <div className="justify-between items-center">
        <h2 className={style.title}>Photos</h2>
        <Link
          to="edit-photos"
          onClick={event => {
            event.preventDefault()
            navigate('edit-photos')
          }}
        >
          <Button variant={COMPACT}>{loadedImages?.length ? 'Edit' : 'Add'} photos</Button>
        </Link>
      </div>

      {loadedImages?.length > 0 ? (
        <div className={style.grid}>
          {loadedImages.map((imageUrl, index) => (
            <div key={index}>
              <img className={style.image} src={`data:image/png;base64, ${imageUrl}`} alt="" />
            </div>
          ))}
        </div>
      ) : (
        <Typography>You haven't uploaded any photos yet</Typography>
      )}
    </div>
  )
}

const style = {
  wrapper: css`
    padding: 18px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 20px 0px #00000012;
    background-color: white;

    @media ${MOBILE_VIEWPORT} {
      padding: 0;
    }
  `,
  title: css`
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;

    @media ${MOBILE_VIEWPORT} {
      margin-bottom: 5px;
      font-size: 18px;
    }
  `,
  grid: css`
    display: flex;
    height: calc(218px + 5px);
    gap: 15px;
    margin-top: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 5px;

    ::-webkit-scrollbar {
      height: 10px; /* Ширина скроллбара */
    }
    /* Стилизация ползунка скроллбара */
    ::-webkit-scrollbar-thumb {
      background-color: #0074d9; /* Цвет ползунка */
      border-radius: 5px; /* Скругленные углы ползунка */
    }
    /* Стилизация трека скроллбара (фон) */
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Цвет фона трека */
    }
  `,
  image: css`
    object-fit: cover;
    height: 100%;
  `
}

export default Photos
