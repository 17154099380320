import { css, cx } from '@emotion/css'

import Menu, { STATUS } from 'components/common/Menu'
import Action from 'components/common/Action'
import StatusIcon from 'images/status.svg'

export const [INACTIVE_STATUS, ACTIVE_STATUS] = [2, 1]

const HomeMenu = ({ className, businessStatus, updateProfile, ...otherProps }) => {
  return (
    <div className={cx('items-center', className)} {...otherProps}>
      <Menu variant={STATUS} active={businessStatus === ACTIVE_STATUS}>
        <Action
          Icon={<StatusIcon />}
          description="Saved automatically"
          selected={businessStatus === ACTIVE_STATUS}
          onClick={() => updateProfile({ data: { business_status: ACTIVE_STATUS } })}
        >
          Active
        </Action>

        <Action
          Icon={<StatusIcon className="grayscale" />}
          description="Saved as draft"
          selected={businessStatus === INACTIVE_STATUS}
          onClick={() => updateProfile({ data: { business_status: INACTIVE_STATUS } })}
        >
          Inactive
        </Action>

        <hr className={style.divider} />

        <Action onClick={() => {}}>Preview listing</Action>
      </Menu>
    </div>
  )
}

const style = {
  divider: css`
    width: calc(100% + 30px);
    height: 1px;
    margin: 15px 0 0 -15px;
    border: none;
    background-color: #dcdcdc;
  `
}

export default HomeMenu
