import { useId, FC, MutableRefObject } from 'react'
import { css, cx } from '@emotion/css'
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'

import Label from './Label'

export type TextareaProps = TextareaAutosizeProps & {
  textareaRef?: MutableRefObject<any>
  label?: string
  error?: boolean
}

const Textarea: FC<TextareaProps> = ({ className, textareaRef, label, error, ...otherProps }) => {
  const id = useId()

  return (
    <div className={cx('flex-col', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <TextareaAutosize
        className={cx(style.textarea, { [style.error]: error })}
        id={id}
        ref={textareaRef}
        {...otherProps}
      />
    </div>
  )
}

const style = {
  textarea: css`
    padding: 12px 16px;
    border: 1px solid #adaabf;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #322b5f14;
    outline: none;
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    resize: none;

    :focus {
      box-shadow: 0px 0px 0px 3px #ded9fb;
    }

    :active {
      border: 1px solid #5663ff;
      text-shadow: 0px 0px 1px black;
    }

    ::placeholder {
      color: #84809f;
    }
  `,
  error: css`
    border: 1px solid #ff5631;
  `
}

export default Textarea
