import React, { useCallback, useEffect, useMemo, useState, useRef, RefObject } from 'react'
import { Calendar, DateLocalizer, SlotPropGetter, dateFnsLocalizer } from 'react-big-calendar'
import { format, parse, getDay, startOfWeek } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import he from 'date-fns/locale/he'
import CalendarDrawer from 'components/CalendarComponents/CalendarDrawer'
import { useAxios } from 'frontend-essentials'
import { IActivity, ISlotInfo } from 'types/types'
import ColorHash from 'color-hash'
import useStore, { UserStore } from 'hooks/useStore'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import moment from 'moment'
import { activityDrawerSizes } from 'utils/constants/sizes.constants'
import { getScheduleTimes } from 'utils/helpers/schedule.helpers'

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'en-US': enUS, he }
})
const colorHash = new ColorHash()

const CalendarContainer = styled.div<{ $isOpenActivityDrawer: boolean }>(({ $isOpenActivityDrawer }) => {
  return css`
    width: calc(100% - ${$isOpenActivityDrawer ? activityDrawerSizes.width : 0}px);
  `
})
// const CalendarContainer = styled.div`
//   .rbc-day-bg {
//     background-color: lightgreen;
//   }
// `

interface IEvent {
  id: number
  title: string
  start: string
  end: string
  desc?: string
  allDay?: boolean
}

const MyCalendar = () => {
  const { user, activityDrawer, setActivityDrawer } = useStore((state: UserStore) => state)
  const [events, setEvents] = useState<IEvent[]>([])
  const [userActivities, setUserActivities] = useState<IActivity[]>([])
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null)
  const [selectedSlot, setSelectedSlot] = useState<ISlotInfo | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(activityDrawer.fromDate ?? null)

  const { data: { response: { activities = [] } = {} } = {}, activate: getActivities } = useAxios({
    params: {
      user_id: user?.userId,
      no_images_no_pagination: 0
    },
    url: 'activities',
    method: 'get',
    uuid: 'activities',
    camelCased: true,
    onSuccess({ data }) {
      const responseEvents = data.response.activities.flatMap((activity: IActivity) => {
        const activityTitle = `Activity: ${activity.title} Instructor: ${activity.instructorName}`
        return activity.schedule.activityDates.map(date => {
          return {
            id: activity.id,
            title: activityTitle,
            start: date.start,
            end: date.end
          }
        })
      })
      setUserActivities(data.response.activities)
      setEvents(responseEvents)
    },
    onError(res) {
      console.log(res)
    }
  })

  const selectedActivity = (eventId: number) => userActivities.filter(el => el.id === eventId)[0]

  const handleEventClick = (event: IEvent) => {
    setSelectedEvent(event)
    setSelectedSlot(null)
    const activity = selectedActivity(event.id)
    setActivityDrawer({
      open: true,
      activityDrawerType: 'calendar',
      id: activity?.id,
      title: activity?.title,
      //images
      image: activity?.mainImage,
      types: activity?.types,
      instructorName: activity?.instructorName,
      description: activity?.description,
      requirements: activity?.requirements,
      locationID: activity?.location.id,
      repeats: activity?.schedule?.recurring.id,
      fromDate: activity && getScheduleTimes(activity.schedule).fromDate,
      toDate: activity && getScheduleTimes(activity.schedule).toDate,
      booking: activity?.booking,
      cancellationPolicy: activity?.cancellationPolicy,
      onSave: getActivities
    })
    console.log(event)
  }
  const handleSelectSlot = (slotInfo: ISlotInfo) => {
    setSelectedSlot(slotInfo)
    setSelectedEvent(null)
    // setShowDrawer(true)
    const now = new Date()
    const time = slotInfo.start.setHours(now.getHours() + 1, now.getMinutes())
    console.log(time)
    const date = new Date(time!)
    setActivityDrawer({ open: true, activityDrawerType: 'create', onSave: getActivities, fromDate: date! })
    setSelectedDate(slotInfo.start)
  }
  // const handleCloseDrawer = () => {
  //   setSelectedSlot(null)
  //   setSelectedEvent(null)
  //   // setShowDrawer(false)
  //   setActivityDrawer({ open: false })
  // }
  const dayPropGetter = date => {
    let backgroundColor
    if (
      date.getDate() === selectedDate?.getDate() &&
      date.getMonth() === selectedDate?.getMonth() &&
      date.getFullYear() === selectedDate?.getFullYear()
    ) {
      backgroundColor = '#b8d8e7'
    }
    const style = {
      backgroundColor
    }
    return {
      style: style
    }
  }

  return (
    <CalendarContainer $isOpenActivityDrawer={activityDrawer.open}>
      <Calendar
        views={['day', 'work_week', 'week', 'month']}
        defaultView="month"
        localizer={localizer}
        events={events}
        eventPropGetter={event => ({
          style: {
            backgroundColor: colorHash.hex(event.title)
          }
        })}
        dayPropGetter={dayPropGetter}
        onSelectEvent={handleEventClick}
        startAccessor={event => {
          return new Date(event.start)
        }}
        endAccessor={event => {
          return new Date(event.end)
        }}
        style={{ height: 700 }}
        selectable
        onSelectSlot={handleSelectSlot}
        onNavigate={date => {
          setSelectedDate(date)
        }}
        date={
          selectedDate!
          // new Date()
        }
      />
      {/* {activityDrawer.open && (
        <CalendarDrawer
          activities={activities}
          slotInfo={selectedSlot}
          selectedEvent={selectedEvent}
          onClose={handleCloseDrawer}
        />
      )} */}
    </CalendarContainer>
  )
}

export default MyCalendar
