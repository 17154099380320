import { useCallback, useEffect, useState } from 'react'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { axios, useAxios } from 'frontend-essentials'
import useStore from 'hooks/useStore'
import useScrollToTop from 'hooks/useScrollToTop'
import Layout from 'components/Layout'
import Home from 'pages/Home'
import Login from 'pages/Login'
import EditPhotos from 'pages/EditPhotos'
import Activities from 'pages/Activities'
import Calendar from 'pages/Calendar'
import Instructors from 'pages/Instructors'
import Reviews from 'pages/Reviews'
import ActivityDrawerWrapper from 'components/ActivityDrawers/ActivityDrawerWrapper'

const App = () => {
  const { user, userInfo, setUserInfo, activityDrawer, setActivityDrawer } = useStore(state => state)

  const navigate = useNavigate()

  const { activate: getServiceProviderDetails } = useAxios({
    method: 'get',
    manual: true,
    camelCased: true,
    purgeNull: true,
    onSuccess: ({ data }) => {
      setUserInfo(data.response)
    }
  })

  useScrollToTop(user)

  useEffect(() => {
    if (user) getServiceProviderDetails({ url: `profile/service-provider/${user?.userId}` })
    else navigate('/')
  }, [user, getServiceProviderDetails])

  if (!user) return <Login />

  axios.defaults.headers.common.Authorization ??= user.accessToken

  const handleCloseActivityDrawer = useCallback(() => {
    setActivityDrawer({ open: false })
  }, [activityDrawer])

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/edit-photos" element={<EditPhotos />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/instructors" element={<Instructors />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
      {activityDrawer.open && <ActivityDrawerWrapper onClose={handleCloseActivityDrawer} />}
    </Layout>
  )
}

export default App
