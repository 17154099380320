import Chip from 'components/common/Chip'
import { FC, useMemo } from 'react'

const useNamesOfActivityTypes = (types, style, activityTypes, chipsView?: boolean) => {
  const chips = types.map(type => {
    if (activityTypes.length > 0) {
      const label = activityTypes.find(({ value }) => value === type)?.label
      if (!label) {
        return null
      }
      return chipsView ? <Chip key={type} className={style.type} label={label} /> : label
    }
    // return <Chip key={type} className={style.type} label={type.label} />
  })

  return chips
}

export default useNamesOfActivityTypes
