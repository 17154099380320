import { useState } from 'react'
import { useAxios } from 'frontend-essentials'
import { css } from '@emotion/css'

import OAuthSignIn from 'components/OAuthSignIn'
import EmailSignIn, { SIGN_IN, SIGN_UP } from 'components/EmailSignIn'
import { MOBILE_VIEWPORT } from 'styles/constants'
import VeloLogo from 'images/velo-logo.svg'

const Login = () => {
  const [mode, setMode] = useState(SIGN_IN)

  const { loading: signingIn, activate: signIn } = useAxios({
    method: 'post',
    url: 'login',
    manual: true,
    camelCased: true
  })

  const { loading: signingUp, activate: signUp } = useAxios({
    method: 'post',
    url: 'registration',
    manual: true,
    camelCased: true
  })

  const { activate: createUserProfile } = useAxios({
    method: 'post',
    url: 'profile/service-provider/',
    manual: true,
    camelCased: true
  })

  return (
    <div className={style.wrapper}>
      <VeloLogo width="42px" />

      <h1 className={style.title}>Welcome to Velo</h1>

      <h2 className={style.description}>Your journey starts here</h2>

      <OAuthSignIn className={style.oAuthSignIn} createUserProfile={createUserProfile} />

      <div className={style.divider}>
        <hr className={style.separator} />
        <span className={style.dividerDescription}>Or sign {mode === SIGN_IN ? 'in' : 'up'} with email</span>
        <hr className={style.separator} />
      </div>

      <EmailSignIn
        className={style.emailSignIn}
        mode={mode}
        loading={signingIn || signingUp}
        signIn={signIn}
        signUp={signUp}
        createUserProfile={createUserProfile}
      />

      <p className={style.modeSelection}>
        {mode === SIGN_IN ? 'Not registered yet?' : 'Already registered?'}
        <span className={style.switchMode} onClick={() => setMode(mode === SIGN_IN ? SIGN_UP : SIGN_IN)}>
          {mode === SIGN_IN ? 'Create an account' : 'Sign in'}
        </span>
      </p>
    </div>
  )
}

const style = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    margin: max(calc((100vh - 830px) / 2), 20px) auto;

    @media ${MOBILE_VIEWPORT} {
      width: 90%;
      margin-top: 20px;
    }
  `,
  title: css`
    margin-top: 20px;
    font-size: 36px;
    font-weight: 700;
  `,
  description: css`
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
  `,
  oAuthSignIn: css`
    display: flex;
    width: 100%;
    margin-top: 38px;
    row-gap: 20px;
  `,
  divider: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 52px;
  `,
  separator: css`
    width: 160px;
    max-width: 20vw;
    height: 1px;
    border: none;
    background-color: #d9d9d9;
  `,
  dividerDescription: css`
    width: 162px;
    margin: 0 10px;
    font-size: 15px;
    color: #d9d9d9;
  `,
  emailSignIn: css`
    width: 100%;
    margin-top: 48px;
  `,
  modeSelection: css`
    align-self: flex-start;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
  `,
  switchMode: css`
    margin-left: 5px;
    color: #3b48e7;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  `
}

export default Login
