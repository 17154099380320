import { useState, useRef, useEffect } from 'react'
import { Media } from 'frontend-essentials'
import { css, cx } from '@emotion/css'
import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import HomeMenu from 'components/HomeMenu'
import backgroundImage from 'images/background.svg?url'

const HomeHeader = ({ className, businessName: initialBusinessName, businessStatus, updateProfile }) => {
  const [businessName, setBusinessName] = useState(initialBusinessName)

  const ref = useRef()

  useEffect(() => {
    setBusinessName(initialBusinessName)
  }, [initialBusinessName])

  useEffect(() => {
    if (document.activeElement !== ref.current) return

    const timeoutID = setTimeout(() => updateProfile({ data: { business_name: businessName.trim() } }), 2000)

    return () => clearTimeout(timeoutID)
  }, [businessName, updateProfile])

  return (
    <div className={cx(style.wrapper, className)}>
      <span className={style.greeting}>good to see you</span>

      <div className="flex-wrap justify-between">
        <input
          className={style.businessName}
          ref={ref}
          value={businessName}
          placeholder="business name"
          onChange={event => setBusinessName(event.target.value)}
        />

        <Media query={DESKTOP_VIEWPORT}>
          <HomeMenu businessStatus={businessStatus} updateProfile={updateProfile} />
        </Media>
      </div>
    </div>
  )
}

const style = {
  wrapper: css`
    padding: 45px 20px;
    background-image: url(${backgroundImage});
    background-size: cover;
  `,
  greeting: css`
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
  `,
  businessName: css`
    width: 50%;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid transparent;
    outline: none;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    text-overflow: ellipsis;
    color: white;
    background-color: transparent;

    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    @media ${MOBILE_VIEWPORT} {
      width: 95%;
    }
  `
}

export default HomeHeader
