import { useId, FC, InputHTMLAttributes, MutableRefObject } from 'react'
import { css, cx } from '@emotion/css'

import Label from './Label'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  inputClassName?: string
  inputRef?: MutableRefObject<any>
  label?: string
  error?: boolean
}

const Input: FC<InputProps> = ({ className, inputClassName, inputRef, label, error, ...otherProps }) => {
  const id = useId()

  return (
    <div className={cx('flex-col', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <input
        className={cx(style.input, { [style.error]: error }, inputClassName)}
        id={id}
        ref={inputRef}
        {...otherProps}
      />
    </div>
  )
}

const style = {
  input: css`
    height: 42px;
    padding: 12px 16px;
    border: 1px solid #adaabf;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #322b5f14;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    color: black;
    :active {
      border: 1px solid #5663ff;
      /* text-shadow: 0px 0px 1px black; */
    }

    :focus {
      box-shadow: 0px 0px 0px 3px #ded9fb;
    }

    ::placeholder {
      color: #84809f;
    }
  `,
  error: css`
    border: 1px solid #ff5631;
  `
}

export default Input
