import { axios, useAxios } from 'frontend-essentials'
import { css, cx } from '@emotion/css'
import { logout as logoutEndpoint } from 'utils/endpoints.constants'
import useStore from 'hooks/useStore'
import Menu from 'components/common/Menu'
import Action from 'components/common/Action'
import UserMenuIcon from 'images/user-menu.svg'
import SignOutIcon from 'images/sign-out.svg'
import useFacebookSDK from '../services/facebookSDK/useFacebookSDK'

const UserMenu = ({ className }) => {
  const { user, setUser, userInfo, setUserInfo } = useStore(state => state)
  const { logout: fbLogout, checkLoginState: checkFbLoginState } = useFacebookSDK()
  // const { activate: logout } = useAxios({
  //   method: 'get',
  //   url: 'logout',
  //   manual: true,
  //   onSuccess: () => {
  //     setUser()
  //     setUserInfo()
  //   }
  // })
  const logout = async () => {
    const response = await axios.get(logoutEndpoint).then(res => res.status)
    if (response === 200) {
      setUser({})
      setUserInfo(null)
      const loginFBStatus = await checkFbLoginState()
      if (loginFBStatus === 'connected') {
        fbLogout()
      }
    }
  }

  return (
    <Menu className={cx(style.wrapper, className)} Icon={() => <UserMenuIcon width="36px" />}>
      <Action className="pointer-events-none">
        <span className={style.email}>
          {user?.email === '' ? userInfo?.contact.email && userInfo?.contact.email : user?.email}
        </span>
      </Action>

      <Action onClick={logout}>
        <div className="items-center">
          <SignOutIcon width="16px" />

          <span className={style.logout}>Logout</span>
        </div>
      </Action>
    </Menu>
  )
}

const style = {
  wrapper: css`
    position: relative;
    height: 40px;
  `,
  email: css`
    font-size: 15px;
    font-weight: bold;
    color: #8591ff;
  `,
  logout: css`
    margin-left: 8px;
    font-size: 15px;
    font-weight: bold;
  `
}

export default UserMenu
