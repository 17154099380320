import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { axios } from 'frontend-essentials'
import { StyledEngineProvider } from '@mui/material/styles'

import 'service-worker-registration'
import 'styles'
import App from './App'

axios.defaults.baseURL = 'https://my-velo.xyz:3001/'// НЕ ЗАБУДЬ ПОМЕНЯТЬ НА ПРОДАКШЕНЕ
axios.interceptors.response.use(undefined, async err => {
  if (err.response?.status === 401 && !err.config._retry) {
    if (err.request.responseURL.endsWith('jwt/token/refresh/')) {
      localStorage.removeItem('user')
      return window.location.reload()
    }

    const user = JSON.parse(localStorage.user)

    const {
      data: { access }
    } = await axios({
      method: 'post',
      url: 'jwt/token/refresh/',
      data: { refresh: user.refreshToken }
    })

    axios.defaults.headers.common.Authorization = access
    localStorage.setItem('user', JSON.stringify({ ...user, accessToken: access }))

    err.config.headers.Authorization = access
    return axios.request(err.config)
  }
})

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Suspense>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </Suspense>
  </BrowserRouter>
)
