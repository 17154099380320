import React, { FC } from 'react'
import CloseIcon from 'images/close.svg'
import EditIcon from 'images/edit.svg'
import { cx } from '@emotion/css'
import { ActivityDrawerPage, ActivityDrawerType } from 'hooks/useStore'
import MultilineInput from 'components/common/MultilineInput'
import { path } from 'utils/endpoints.constants'

interface Props {
  pathname: string
  activityDrawerPage: ActivityDrawerPage
  activityDrawerType: ActivityDrawerType
  resetActivityDrawerPage: () => void
  style: {
    [key: string]: string
  }
  onClose: () => void
  formValid: boolean
  title: string
  setForm: (values: any) => void
}

const ActivityDrawerHeader: FC<Props> = ({
  pathname,
  activityDrawerPage,
  activityDrawerType,
  resetActivityDrawerPage,
  style,
  onClose,
  formValid,
  title,
  setForm
}) => {
  const isFirstPageActivityDrawer =
    (activityDrawerType === 'edit' && activityDrawerPage === 1) || activityDrawerType === 'create'
  const isLastPageActivityDrawer =
    (activityDrawerType === 'edit' && activityDrawerPage === 2) || activityDrawerType === 'calendar'

  return (
    <>
      <button
        className={style.close}
        type="button"
        onClick={() => {
          onClose()
          resetActivityDrawerPage()
        }}
      >
        <CloseIcon />
      </button>

      <div className={style.titleWrapper}>
        {isLastPageActivityDrawer ? (
          <h3 className={cx(style.title, { [style.titleError]: !formValid && !title })}>{title}</h3>
        ) : (
          <MultilineInput
            title={title}
            setTitle={(title: string) => setForm({ title })}
            placeholder="Activity name"
            initHeight={37}
            lineHeight={37}
            style={{
              width: '300px',
              paddingLeft: '10px',
              paddingRight: '10px',
              border: 'none',
              // color: '#000',
              fontFamily: 'inherit',
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 700
            }}
          />
        )}

        {isFirstPageActivityDrawer && <EditIcon className={style.edit} />}
      </div>
    </>
  )
}

export default ActivityDrawerHeader
