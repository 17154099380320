import { useLocation, useNavigate } from 'react-router-dom'
import { Media } from 'frontend-essentials'
import { css } from '@emotion/css'

import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import UserMenu from 'components/UserMenu'
import VeloLogo from 'images/velo-logo.svg'
import VeloTitle from 'images/velo-title.svg'
import BackIcon from 'images/back.svg'

const backRoutes = ['/edit-photos']

const Header = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <div className={style.wrapper}>
      {backRoutes.includes(pathname) ? (
        <BackIcon className="cursor-pointer" width="18px" onClick={() => navigate(-1)} />
      ) : (
        <Media query={DESKTOP_VIEWPORT}>
          <div className="items-center">
            <VeloLogo width="42px" />
            <VeloTitle className={style.title} />
          </div>
        </Media>
      )}

      <UserMenu className={style.userMenu} />
    </div>
  )
}

const style = {
  wrapper: css`
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 18px;
    box-shadow: 0px 4px 4px 0px #0000001a;
    background-color: white;

    @media ${MOBILE_VIEWPORT} {
      position: relative;
      padding: 16px 16px 0;
      box-shadow: none;
      background-color: transparent;
    }
  `,
  title: css`
    width: 60px;
    margin-left: 8px;
  `,
  userMenu: css`
    margin-left: auto;
  `
}

export default Header
