import { useState, useEffect, memo } from 'react'
import { useAxios } from 'frontend-essentials'

import useChanged from 'hooks/useChanged'
import Card from 'components/common/Card'
import Select from 'components/common/Select'
import Chip from 'components/common/Chip'
import AmenitiesIcon from 'images/amenities.svg'
import PlusIcon from 'images/plus.svg'
import { css } from '@emotion/css'

const Amenities = ({
  className,
  userExpanded,
  saving,
  amenities: initialAmenities = [],
  expand,
  collapse,
  setCardChanged,
  updateProfile
}) => {
  const [allAmenities, setAllAmenities] = useState([])
  const [amenities, setAmenities] = useState(initialAmenities)
  const [addingNewAmenity, setAddingNewAmenity] = useState(false)

  useAxios({
    method: 'get',
    url: 'profile/amenity-options',
    camelCased: true,
    onSuccess: ({ data: { response } }) => {
      setAllAmenities(response.amenityOptions.map(({ id, label }) => ({ label, value: id })))
    }
  })

  const changed = useChanged([[amenities, initialAmenities]])

  const expanded = userExpanded || !initialAmenities.length

  useEffect(() => setCardChanged(changed), [changed])

  const onDiscard = () => {
    setAmenities(initialAmenities)
    setCardChanged(false)
    collapse()
  }

  const handleAddNewAmenity = () => {
    setAddingNewAmenity(true)
  }

  const optionRow = () =>
    addingNewAmenity ? (
      <input
        type="text"
        style={style.optionInput}
        onChange={e => {
          e.preventDefault()
        }}
        autoFocus
      />
    ) : (
      <button
        className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root"
        style={style.optionRow}
        onClick={handleAddNewAmenity}
      >
        + Add new amenity
      </button>
    )

  return (
    <Card
      className={className}
      Icon={AmenitiesIcon}
      title="Amenities"
      expanded={expanded}
      changed={changed}
      saving={saving}
      onClick={expand}
      onSave={() => updateProfile({ data: { amenities } })}
      onDiscard={onDiscard}
    >
      <div className={style.wrapper}>
        {expanded ? (
          <Select
            label="Amenities"
            options={allAmenities}
            value={amenities || []}
            multiple
            required
            onChange={event => setAmenities(event.target.value)}
            optionRow={optionRow}
          />
        ) : (
          amenities.map(amenity => (
            <Chip key={amenity} label={allAmenities.find(({ value }) => value === amenity)?.label} />
          ))
        )}
      </div>
    </Card>
  )
}

export default memo(Amenities)

const style = {
  wrapper: css`
    display: flex;
    align-items: end;
  `,
  button: css`
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    gap: 6px;
    span {
      font-size: 16px;
    }
  `,
  optionRow: {
    width: '100%'
  },
  optionInput: {
    width: 'calc(100% - 30px)',
    height: '45px',
    margin: '0 15px 5px',
    padding: '15px',
    fontSize: '1rem',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    borderRadius: '10px',
    border: '1px solid #adaabf',
    boxShadow: '0px 1px 2px 0px #322b5f14',
    '&:focus': {
      boxShadow: '0px 0px 0px 3px #ded9fb'
    }
  }
}
