import { css, cx } from '@emotion/css'
import type { FC, ButtonHTMLAttributes } from 'react'

export const [PRIMARY, SECONDARY, COMPACT, BORDERLESS] = ['primary', 'secondary', 'compact', 'borderless']

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: string
}

const Button: FC<ButtonProps> = ({ className, variant = PRIMARY, children, ...otherProps }) => {
  return (
    <button className={cx(style.wrapper, style[variant], className)} {...otherProps}>
      {children}
    </button>
  )
}

const style = {
  wrapper: css`
    padding: 14px 20px;
    border: 1px solid #5663ff;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
  `,
  primary: css`
    border: none;
    color: #fff;
    background: linear-gradient(96.25deg, #3b48e7 15.19%, #5663ff 105.27%);
  `,
  secondary: css`
    color: #3b48e7;
    background-color: transparent;
  `,
  compact: css`
    padding: 4px 16px;
    border: 1px solid #b8b8b8;
    border-radius: 100px;
    font-size: 14px;
    font-weight: normal;
    color: #5663ff;
    background-color: transparent;

    :hover {
      background-color: #eeeeee;
    }

    :active {
      background-color: #dcdcdc;
    }
  `,
  borderless: css`
    border: none;
    color: #3b48e7;
    background-color: transparent;
  `
}

export default Button
