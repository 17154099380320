import { format, differenceInDays } from 'date-fns'
import { Media, useAxios, useMedia } from 'frontend-essentials'
import { Rating, styled } from '@mui/material'
import { css, cx } from '@emotion/css'
import { DateTime } from 'luxon'
import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import useStore from 'hooks/useStore'
import Action from 'components/common/Action'
import Chip from 'components/common/Chip'
import Menu, { OPTIONS, STATUS } from 'components/common/Menu'
import StatusIcon from 'images/status.svg'
import StarIcon from 'images/star.svg'
import { useState, useMemo } from 'react'
import useNamesOfActivityTypes from 'hooks/useNamesOfActivityTypes'

const CANCELLATION_POLICY = ['No penalty', 'Moderate', 'Strict']

const Activity = props => {
  const {
    id,
    title,
    types,
    images,
    mainImage,
    rating,
    votes,
    description,
    instructorName,
    schedule,
    cancellationPolicy,
    status,
    onSave,
    onClick,
    isSelected
  } = props

  const { userInfo, setActivityDrawer } = useStore(({ userInfo, setActivityDrawer, setInfoDrawer, infoDrawer }) => ({
    userInfo,
    setActivityDrawer,
    setInfoDrawer,
    infoDrawer
  }))

  const [allActivityTypes, setAllActivityTypes] = useState([])

  const active = status === 1

  const { desktop } = useMedia({ desktop: DESKTOP_VIEWPORT })

  const { activate: changeActivityStatus } = useAxios({
    url: `activity/${id}`,
    method: 'patch',
    manual: true,
    onSuccess: onSave
  })
  const { activate: deleteActivity } = useAxios({
    url: `activity/${id}`,
    method: 'delete',
    manual: true,
    onSuccess: onSave
  })
  const { activate: duplicateActivity } = useAxios({
    url: 'duplicate_activity',
    method: 'post',
    manual: true,
    onSuccess: onSave
  })
  // get activity types
  useAxios({
    method: 'get',
    url: 'activity-type-options',
    camelCased: true,
    onSuccess: ({ data: { response } }) => {
      setAllActivityTypes(response.activityTypeOptions.map(({ id, label }) => ({ label, value: id })))
    }
  })
  // const typeChips = useMemo(
  //   () =>
  //     types.map(type => {
  //       console.log(type)
  //       if (allActivityTypes.length > 0) {
  //         const label = allActivityTypes.find(({ value }) => value === type)?.label
  //         if (!label) {
  //           return null
  //         }
  //         return <Chip key={type} className={style.type} label={label} />
  //       }
  //       // return <Chip key={type} className={style.type} label={type.label} />
  //     }),
  //   [types]
  // )
  const typeChips = useNamesOfActivityTypes(types, style, allActivityTypes, true)

  const activityTimes = schedule.activityTimes

  let frequency = ''
  let startTime = ''
  let endTime = ''

  if (activityTimes[0].includes(',')) {
    const [freq, timeRange] = activityTimes[0].split(', ')
    const [start, end] = timeRange.split('-').map(time => {
      const dt = DateTime.fromISO(time, { zone: 'utc' }).toLocal()
      return dt.toFormat('HH:mm')
    })

    frequency = freq
    startTime = start
    endTime = end
  } else {
    ;[startTime, endTime] = activityTimes[0].split('-').map(time => {
      const dt = DateTime.fromISO(time, { zone: 'utc' }).toLocal()
      return dt.toFormat('HH:mm')
    })
  }

  const handleClickListener = e => {
    onClick(id)
  }

  return (
    <div key={id} className={`${style.wrapper} ${isSelected && style.selected}`} onClick={handleClickListener}>
      <div style={{ backgroundImage: `url(data:image/png;base64,${mainImage})` }} className={style.image}></div>

      <div className={style.details}>
        <div className={style.head}>
          <div className={style.title}>
            <Media query={MOBILE_VIEWPORT}>
              <StatusIcon className={cx(style.statusIndication, { [!active]: 'grayscale' })} />
            </Media>

            <h2 className={style.titleText} title={title}>
              {title}
            </h2>
          </div>

          <div className={style.types}>{typeChips}</div>

          <Media query={DESKTOP_VIEWPORT}>
            <Menu className={style.status} variant={STATUS} active={active} onClick={e => e.stopPropagation()}>
              <Action
                Icon={<StatusIcon />}
                description="Saved automatically"
                selected={active}
                onClick={() => changeActivityStatus({ data: { status: 1 } })}
              >
                Active
              </Action>

              <Action
                Icon={<StatusIcon className="grayscale" />}
                description="Saved as draft"
                selected={!active}
                onClick={() => changeActivityStatus({ data: { status: 3 } })}
              >
                Inactive
              </Action>
            </Menu>
          </Media>

          <Menu className={style.options} variant={OPTIONS} onClick={e => e.stopPropagation()}>
            <Action onClick={() => setActivityDrawer({ open: true, ...props })}>Preview listing</Action>

            <Action onClick={() => {}}>Get share link</Action>

            <Action onClick={() => duplicateActivity({ data: { id } })}>Duplicate activity</Action>

            <Action onClick={deleteActivity}>Delete activity</Action>
          </Menu>
        </div>

        <Media query={DESKTOP_VIEWPORT}>
          <div className={style.rating}>
            <Rating
              className={`pointer-events-none ` + style.ratingStar}
              name="disabled"
              value={+rating}
              precision={0.1}
              emptyIcon={<StarIcon height={19} />}
            />
            <span className={style.reviews}>{votes} Reviews</span>
          </div>
        </Media>

        <Media query={DESKTOP_VIEWPORT}>
          <p className={style.description} title={description}>
            {description}
          </p>
        </Media>

        <div className={style.info}>
          <div className={style.infoSection}>
            <Media query={DESKTOP_VIEWPORT}>
              <span className={style.infoTitle}>Instructor name</span>
            </Media>

            <span className={style.infoValue}>{instructorName}</span>
          </div>

          <div className={style.infoSection}>
            <Media query={DESKTOP_VIEWPORT}>
              <span className={style.infoTitle}>Times</span>
            </Media>

            {schedule.activityTimes && (
              <span className={style.infoValue}>
                {frequency} {startTime} - {endTime}
              </span>
            )}
          </div>

          <Media query={DESKTOP_VIEWPORT}>
            <div className={style.infoSection}>
              <span className={style.infoTitle}>Cancellation policy</span>
              <span className={style.infoValue}>{CANCELLATION_POLICY[cancellationPolicy.policyType]}</span>
            </div>
          </Media>

          {schedule.upcoming && (
            <div className={style.infoSection}>
              <Media query={DESKTOP_VIEWPORT}>
                <span className={style.infoTitle}>Upcoming</span>
              </Media>

              <span className={cx(style.infoValue, style.upcoming)}>
                Next{desktop ? ' occurs' : ''} on {format(new Date(schedule.upcoming), 'MMMM d')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// const Wrapper = styled('div')(({ isClicked }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: '24px 20px',
//   borderRadius: '20px',
//   boxShadow: '0px 0px 20px 0px #00000012',
//   border: `3px solid ${isClicked ? 'rgb(217, 252, 252)' : 'transparent'}`,
//   cursor: 'pointer',
//   boxShadow: `${isClicked ? '0px 0px 10px 5px rgba(134, 166, 166, 0.5)' : 'none'}`,
//   transition: `border-color 0.3s, background-color 0.3s, box-shadow 0.3s`,

//   '&:hover': {
//     borderColor: 'rgb(217, 252, 252)',
//     backgroundColor: 'rgb(217, 252, 252)',
//     boxShadow: '0px 0px 10px 5px rgba(134, 166, 166, 0.5)'
//   },

//   '@media': {
//     [MOBILE_VIEWPORT]: {
//       alignItems: 'flex-start',
//       padding: '15px 10px'
//     }
//   }
// }))
const style = {
  wrapper: css`
    display: flex;
    padding: 24px 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    height: 200px;

    cursor: pointer;
    transition: all 0.3s ease 0s;
    &:hover {
      box-shadow: 0 0 10px 5px rgba(134, 166, 166, 0.5);
    }
    @media ${MOBILE_VIEWPORT} {
      align-items: flex-start;
      padding: 15px 10px;
    }
  `,
  selected: css`
    &:hover {
      box-shadow: 0 0 10px 5px rgba(134, 166, 166, 0.5), inset 0px 0px 0px 3px rgba(86, 99, 255, 1);
    }
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07), inset 0px 0px 0px 3px rgba(86, 99, 255, 1);
  `,
  image: css`
    flex-shrink: 0;
    width: 230px;
    height: 150px;
    /* padding: 100px 0; */
    margin-right: 20px;
    background-position: center;
    background-size: cover;

    @media ${MOBILE_VIEWPORT} {
      width: 100px;
      height: 130px;
    }
  `,
  details: css`
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
    /* width: 100%; */
    @media ${MOBILE_VIEWPORT} {
      width: calc(100% - 120px);
    }
  `,
  head: css`
    position: relative;
    display: flex;
    align-items: center;

    @media ${MOBILE_VIEWPORT} {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  title: css`
    display: flex;
    align-items: center;
    width: 25%;

    @media ${MOBILE_VIEWPORT} {
      width: calc(100% - 10px);
    }
  `,
  statusIndication: css`
    flex-shrink: 0;
    width: 16px;
  `,
  infoValue: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  titleText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    font-weight: 500;

    @media ${MOBILE_VIEWPORT} {
      margin-left: 8px;
      padding-right: 10px;
      font-size: 18px;
    }
  `,
  types: css`
    @media ${DESKTOP_VIEWPORT} {
      margin-left: 20px;
    }
  `,
  type: css`
    @media ${MOBILE_VIEWPORT} {
      margin-top: 10px;
    }
  `,
  status: css`
    margin-left: auto;
  `,
  options: css`
    margin: 0 -5px 0 20px;

    @media ${MOBILE_VIEWPORT} {
      position: absolute;
      top: 0;
      right: 0;
    }
  `,
  rating: css`
    /* margin: 15px 0 0 -2px; */
    display: flex;
    align-items: center;
    margin-top: 8px;
  `,
  ratingStar: css`
    display: flex;
    gap: 6px;
    svg {
      fill: rgb(231, 231, 231);
    }
  `,
  reviews: css`
    margin-left: 10px;
    font-size: 15px;
  `,
  description: css`
    display: -webkit-box;
    margin-top: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 15px;
    font-weight: 300;
  `,
  info: css`
    display: flex;
    margin-top: 22px;
    @media ${MOBILE_VIEWPORT} {
      flex-direction: column;
      margin-top: 10px;
    }
  `,
  infoSection: css`
    display: flex;
    flex-direction: column;
    width: 25%;

    @media ${MOBILE_VIEWPORT} {
      width: 100%;
      margin: 0;
    }
  `,
  infoTitle: css`
    font-size: 14px;
    font-weight: 300;
    color: #707070;
  `,
  // infoValue: css`
  //   margin-top: 2px;
  //   font-size: 15px;
  // `,
  upcoming: css`
    overflow: hidden;
    font-weight: 500;
    color: #5663ff;
  `
}

export default Activity
