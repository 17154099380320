import { useState, useEffect, memo } from 'react'
import { css } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import useChanged from 'hooks/useChanged'
import Card from 'components/common/Card'
import Textarea from 'components/common/Textarea'
import DescriptionIcon from 'images/business.svg'

const Description = ({
  className,
  userExpanded,
  saving,
  description: initialDescription = '',
  expand,
  collapse,
  setCardChanged,
  updateProfile
}) => {
  const [description, setDescription] = useState(initialDescription)

  const changed = useChanged([[description, initialDescription]])

  const expanded = userExpanded || !initialDescription

  useEffect(() => setCardChanged(changed), [changed])

  const onDiscard = () => {
    setDescription(initialDescription)
    setCardChanged(false)
    collapse()
  }

  return (
    <Card
      className={className}
      Icon={DescriptionIcon}
      title="Business description"
      expanded={expanded}
      changed={changed}
      saving={saving}
      onClick={expand}
      onSave={() => updateProfile({ data: { description } })}
      onDiscard={onDiscard}
    >
      {expanded ? (
        <div className="flex-wrap">
          <Textarea
            className={style.section}
            label="Description"
            value={description}
            minRows={3}
            required
            onChange={event => setDescription(event.target.value)}
          />
        </div>
      ) : (
        <p>{description}</p>
      )}
    </Card>
  )
}

const style = {
  section: css`
    width: 33%;

    @media ${MOBILE_VIEWPORT} {
      width: 100%;
    }
  `
}

export default memo(Description)
