import { css, cx } from '@emotion/css'
import type { FC, LabelHTMLAttributes } from 'react'

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement>

const Label: FC<LabelProps> = ({ className, children, ...otherProps }) => {
  return (
    <label className={cx(style.wrapper, className)} {...otherProps}>
      {children}
    </label>
  )
}

const style = {
  wrapper: css`
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #322b5f;
  `
}

export default Label
