import { useState, useMemo } from 'react'
import { useAxios } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import useStore from 'hooks/useStore'
import Label from 'components/common/Label'
import Checkbox from 'components/common/Checkbox'
import Button from 'components/common/Button'
import UserIcon from 'images/user.svg'
import LockIcon from 'images/lock.svg'
import ValidIcon from 'images/valid.svg'
import InvalidIcon from 'images/invalid.svg'

export const [SIGN_IN, SIGN_UP] = ['Sign In', 'Sign Up']

const EmailSignIn = ({ className, mode, loading, signIn, signUp, createUserProfile }) => {
  const { setUser } = useStore(state => state)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [rememberMe, setRememberMe] = useState(true)

  const passwordValid = useMemo(() => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{1,}$/.test(password), [password])

  const onSubmit = event => {
    event.preventDefault()

    if (!passwordValid) return setPasswordError(true)

    mode === SIGN_IN
      ? signIn({
          data: { email, password },
          onSuccess: ({ data }) => {
            const response = data.response
            console.log(response.user.email)
            setUser({ ...data.response, rememberMe })
          }
        })
      : signUp({
          data: {
            email,
            password1: password,
            password2: password,
            is_service_provider: true
          },
          onSuccess: async ({ data }) => {
            await createUserProfile({ headers: { Authorization: data.accessToken } })
            setUser({ ...data.response, rememberMe })
          }
        })
  }

  return (
    <form className={className} onSubmit={onSubmit}>
      <div className="flex-col w-full">
        <Label>Email address</Label>

        <div className={style.field}>
          <UserIcon className={style.userIcon} />
          <input
            className={style.input}
            type="email"
            value={email}
            required
            onChange={event => setEmail(event.target.value)}
          />
        </div>
      </div>

      <div className={cx('flex-col w-full', style.passwordField)}>
        <div className="justify-between">
          <Label>Password</Label>
          {password && (
            <span className={style.passwordToggle} onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? 'Hide' : 'Show'}
            </span>
          )}
        </div>

        <div className={cx(style.field, { [style.fieldValid]: passwordValid, [style.fieldInvalid]: passwordError })}>
          <LockIcon className={style.lockIcon} />
          <input
            className={style.input}
            type={passwordVisible ? 'text' : 'password'}
            value={password}
            required
            onChange={event => {
              setPassword(event.target.value)
              setPasswordError(false)
            }}
          />
          {passwordError ? <InvalidIcon width="24px" /> : passwordValid && <ValidIcon width="24px" />}
        </div>

        <p className={style.passwordDescription}>Your password must contain at least one letter and one number</p>
      </div>

      <div className={style.rememberMe}>
        <Checkbox
          labelClassName={style.rememberMeLabel}
          label="Remember me"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
        />

        <span className={style.forgotPassword}>Forgot password?</span>
      </div>

      <Button className={style.submit} disabled={loading}>
        {mode}
      </Button>
    </form>
  )
}

const style = {
  field: css`
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    column-gap: 8px;
    border: 1px solid #adaabf;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #322b5f14;

    :focus-within,
    :active {
      box-shadow: 0px 0px 0px 3px #ded9fb;
    }

    :active {
      border: 1px solid #5663ff;
    }
  `,
  fieldValid: css`
    border: 1px solid #00d8be;
  `,
  fieldInvalid: css`
    border: 1px solid #ff5631;
  `,
  input: css`
    flex: 1;
    height: 24px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #322b5f;
    background-color: transparent;
  `,
  icon: css`
    width: 24px;
  `,
  userIcon: css`
    width: 24px;
  `,
  passwordField: css`
    margin-top: 20px;
  `,
  lockIcon: css`
    width: 24px;
  `,
  passwordToggle: css`
    font-size: 14px;
    font-weight: 500;
    color: #84809f;
    cursor: pointer;
  `,
  passwordDescription: css`
    margin-top: 4px;
    font-size: 14px;
    color: #84809f;
  `,
  rememberMe: css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  `,
  rememberMeLabel: css`
    font-size: 14px;
  `,
  forgotPassword: css`
    margin-left: auto;
    font-size: 14px;
    color: #3b48e7;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  `,
  submit: css`
    width: 100%;
    margin-top: 20px;

    :disabled {
      opacity: 0.75;
      cursor: default;
    }
  `
}

export default EmailSignIn
