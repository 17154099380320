// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { css, cx } from '@emotion/css'
import GoogleSignIn from './GoogleSignIn'
import FacebookSignIn from './FacebookSignIn'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { facebookSdkIntegration } from '../../services/facebookSDK/facebookSDK'
import { useLayoutEffect } from 'react'

const OAuthSignIn = ({ className }) => {
  useLayoutEffect(() => {
    facebookSdkIntegration('221373764112543')
  }, [])
  return (
    <div className={cx('flex-col items-center', className)}>
      <GoogleOAuthProvider clientId="597938495629-vsht4dc8791s8iige6q4l80eahir3hm3.apps.googleusercontent.com">
        <GoogleSignIn style={style} />
      </GoogleOAuthProvider>

      <FacebookSignIn style={style} />
    </div>
  )
}

const style = {
  oAuth: css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #322b5f14;
    column-gap: 8px;
  `,
  oAuthText: css`
    font-size: 16px;
    line-height: 19.5px;
    color: #322b5f;
  `
}

export default OAuthSignIn
