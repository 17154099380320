import Button, { COMPACT } from 'components/common/Button'
import Select from 'components/common/Select'
import React, { FC, useRef, RefObject, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import Input from 'components/common/Input'
import Checkbox from 'components/common/Checkbox'
import Textarea from 'components/common/Textarea'
import DatePicker from 'components/common/DatePicker'
import { IBooking } from 'types/types'
import { BOOKING_TIMES, PENALTY_TIMES, REPETITIONS } from 'utils/constans'
import { NavLink } from 'react-router-dom'
import Radio from 'components/common/Radio'
import { cx } from '@emotion/css'
import { ActivityDrawerPage, ActivityDrawerType } from 'hooks/useStore'

interface Props {
  activityDrawerPage: ActivityDrawerPage
  activityDrawerType: ActivityDrawerType
  style: {
    [key: string]: string
  }
  handleOnChangeImages: (event: any) => void
  image: any
  activityTypes: { label: string; value: number }[]
  types: number[]
  formValid: boolean
  setForm: (...values: any) => void
  instructorName: string
  description: string
  requirements: string
  userLocations: any
  locationID: number
  booking: IBooking
  fromDate: Date
  toDate: Date
  repeats: number
  cancellationPolicy: any
  pathname: string
  mobile: boolean
}

const ActivityDrawerMain: FC<Props> = ({
  activityDrawerType,
  style,
  handleOnChangeImages,
  image,
  activityTypes,
  types,
  formValid,
  setForm,
  instructorName,
  description,
  requirements,
  userLocations,
  locationID,
  booking,
  fromDate,
  toDate,
  repeats,
  cancellationPolicy,
  pathname,
  mobile
}) => {
  const inputRef = useRef(null) as RefObject<HTMLInputElement>
  const [hasRequirements, setHasRequirements] = useState(!!requirements)
  const [rememberPolicy, setRememberPolicy] = useState(false)
  const [filledParticipants, setFilledParticipants] = useState(false)

  return (
    <div className={style.main}>
      <div className={style.images}>
        <div className="justify-between items-center">
          {/* <h2 className={style.subtitle}>Photos {images?.length ? `(${images.length})` : ''}</h2> */}
          <Button variant={COMPACT} onClick={() => inputRef.current?.click()}>
            Add Photos
          </Button>
          <input
            className="hidden"
            ref={inputRef}
            type="file"
            accept="image/*,.jpeg,.jpg,.png"
            //multiple
            onChange={handleOnChangeImages}
          />
        </div>
        {/* REVIEW alt={image.name} */}
        {image && (
          <img
            className={style.photo}
            src={activityDrawerType === 'create' ? URL.createObjectURL(image) : `data:image/jpeg;base64, ${image}`}
          />
        )}
      </div>

      <Select
        className={style.field}
        label="Activity type"
        options={activityTypes}
        value={types || []}
        multiple
        error={!formValid && !types.length}
        onChange={(event: SelectChangeEvent<unknown>) => {
          setForm({ types: event.target.value as number[] })
        }}
      />

      <Input
        className={style.field}
        label="Instructor name"
        value={instructorName}
        error={!formValid && !instructorName}
        onChange={event => setForm({ instructorName: event.target.value })}
      />

      <Input
        className={style.field}
        label="Activity description"
        value={description}
        error={!formValid && !description}
        onChange={event => setForm({ description: event.target.value })}
      />

      <Checkbox
        className={style.field}
        label="This activity has prerequisites/requirements"
        checked={hasRequirements}
        onChange={setHasRequirements}
      />

      {hasRequirements && (
        <Textarea
          className={style.field}
          label="Requirements"
          value={requirements}
          minRows={3}
          onChange={event => setForm({ requirements: event.target.value })}
        />
      )}

      <Select
        className={style.field}
        label="Location or room"
        options={userLocations() ?? []}
        value={locationID || ''}
        error={!formValid && !locationID}
        onChange={(event: SelectChangeEvent<unknown>) => setForm({ locationID: event.target.value as number })}
      />
      {/* REVIEW Нужен ли чекбокс для определения основной локации, как в макете */}
      <Input
        className={style.field}
        label="Number of participants"
        type="number"
        min={1}
        value={filledParticipants ? booking.totalSpots : ''}
        onChange={event => {
          !filledParticipants && setFilledParticipants(true)
          const value = event.target.valueAsNumber < 1 ? 1 : event.target.valueAsNumber
          setForm({ booking: { ...booking, totalSpots: value } })
        }}
      />

      {activityDrawerType === 'create' && (
        <>
          <DatePicker
            className={style.date}
            label="Activity occurs"
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={value => setForm({ fromDate: value })}
            onToDateChange={value => setForm({ toDate: value })}
          />

          <Select
            className={style.field}
            label="Repeats"
            options={REPETITIONS.map((repetition, ind) => ({ label: repetition, value: ind }))}
            value={repeats}
            onChange={(event: SelectChangeEvent<unknown>) => setForm({ repeats: event.target.value as number })}
          />
        </>
      )}

      <div className={style.radioSection}>
        <h2 className={style.subtitle}>Booking</h2>

        <div className={style.radioOption}>
          <Radio
            checked={booking.bookingOptionId === 0}
            onChange={() => setForm({ booking: { ...booking, bookingOptionId: 0 } })}
          />

          <div className={cx(style.card, { [style.cardDisabled]: booking.bookingOptionId > 0 })}>
            <h3 className={style.radioOptionName}>Request to book</h3>

            <p>
              Business will be notified when users requests to schedule this activity. Business must confirm booking.
            </p>
          </div>
        </div>

        <div className={style.radioOption}>
          <Radio
            checked={booking.bookingOptionId > 0}
            onChange={() => setForm({ booking: { ...booking, bookingOptionId: 1 } })}
          />

          <div className={cx(style.card, { [style.cardDisabled]: booking.bookingOptionId === 0 })}>
            <h3 className={style.radioOptionName}>Instant booking</h3>

            <p>
              Users can book anytime up to
              <select
                className={cx(style.radioOptionInput, style.radioOptionSelect)}
                value={booking.bookingOptionId}
                onChange={event => setForm({ booking: { ...booking, bookingOptionId: +event.target.value } })}
              >
                {BOOKING_TIMES.map((time, ind) => (
                  <option key={time} value={ind + 1}>
                    {time}
                  </option>
                ))}
              </select>
              minutes before scheduled activity time.
            </p>
          </div>
        </div>
      </div>

      <div className={style.radioSection}>
        <h2 className={style.subtitle}>Cancellation policy</h2>

        <div className={style.radioOption}>
          <Radio
            checked={cancellationPolicy.policyType === 0}
            onChange={() => setForm({ cancellationPolicy: { ...cancellationPolicy, policyType: 0 } })}
          />

          <div className={cx(style.card, { [style.cardDisabled]: cancellationPolicy.policyType !== 0 })}>
            <h3 className={style.radioOptionName}>No penalty</h3>

            <p>Users can cancel at anytime without penalty.</p>
          </div>
        </div>

        <div className={style.radioOption}>
          <Radio
            checked={cancellationPolicy.policyType === 1}
            onChange={() => setForm({ cancellationPolicy: { ...cancellationPolicy, policyType: 1 } })}
          />

          <div className={cx(style.card, { [style.cardDisabled]: cancellationPolicy.policyType !== 1 })}>
            <h3 className={style.radioOptionName}>Moderate</h3>

            <p>
              Users can cancel at anytime prior to
              <select
                className={cx(style.radioOptionInput, style.radioOptionSelect)}
                value={cancellationPolicy.penaltyTimeId}
                onChange={event => {
                  setForm({ cancellationPolicy: { ...cancellationPolicy, penaltyTimeId: +event.target.value } })
                }}
              >
                {PENALTY_TIMES.map((time, ind) => (
                  <option key={time} value={ind}>
                    {time}
                  </option>
                ))}
              </select>
              without penalty. If they cancel after this time they will be charged
              <input
                className={style.radioOptionInput}
                type="number"
                min="1"
                value={cancellationPolicy.penaltyPrice}
                onChange={event => {
                  setForm({
                    cancellationPolicy: { ...cancellationPolicy, penaltyPrice: event.target.valueAsNumber }
                  })
                }}
              />
              <strong>NIS</strong>.
            </p>
          </div>
        </div>

        <div className={style.radioOption}>
          <Radio
            checked={cancellationPolicy.policyType === 2}
            onChange={() => setForm({ cancellationPolicy: { ...cancellationPolicy, policyType: 2 } })}
          />

          <div className={cx(style.card, { [style.cardDisabled]: cancellationPolicy.policyType !== 2 })}>
            <h3 className={style.radioOptionName}>Strict</h3>

            <p>
              Users do not have the option to cancel. They will be charged
              <input
                className={style.radioOptionInput}
                type="number"
                min="1"
                value={cancellationPolicy.penaltyPrice}
                onChange={event => {
                  setForm({
                    cancellationPolicy: { ...cancellationPolicy, penaltyPrice: event.target.valueAsNumber }
                  })
                }}
              />
              NIS if they do not show.
            </p>
          </div>
        </div>

        {!cancellationPolicy && (
          <Checkbox
            className={style.policyCheckbox}
            labelClassName={style.checkboxLabel}
            label="Use my cancellation policy for all activities"
            checked={rememberPolicy}
            onChange={setRememberPolicy}
          />
        )}
      </div>
    </div>
  )
}

export default ActivityDrawerMain
