import { useRef, FC, FormHTMLAttributes } from 'react'
import { useMedia } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT, DESKTOP_VIEWPORT } from 'styles/constants'
import Button, { PRIMARY, SECONDARY } from './Button'

export type CardProps = FormHTMLAttributes<HTMLFormElement> & {
  Icon: any
  title: string
  expanded: boolean
  changed: boolean
  saving: boolean
  onSave: () => void
  onDiscard: () => void
}

const Card: FC<CardProps> = ({
  className,
  Icon,
  title,
  expanded,
  changed,
  saving,
  children,
  onSave,
  onDiscard,
  ...otherProps
}) => {
  const ref: any = useRef()
  const { mobile, desktop } = useMedia({ mobile: MOBILE_VIEWPORT, desktop: DESKTOP_VIEWPORT })

  return (
    <form
      className={cx(style.wrapper, { [style.expanded]: expanded }, className)}
      ref={ref}
      onSubmit={event => {
        event.preventDefault()
        onSave()
      }}
      {...otherProps}
    >
      {(desktop || !expanded) && (
        <div className={style.iconWrap}>
          <Icon className={cx(style.icon, { [style.iconOpaque]: expanded })} />
        </div>
      )}

      <div className={cx(style.content, { 'w-full': mobile && expanded })}>
        <div className={style.header}>
          {mobile && expanded && (
            <div className={style.iconWrap}>
              <Icon className={cx(style.icon, { [style.iconOpaque]: expanded })} />
            </div>
          )}

          <h2 className={style.title}>{title}</h2>
        </div>
        {children}

        {expanded && (
          <div className={style.actions}>
            <Button
              className={style.action}
              variant={SECONDARY}
              type="button"
              disabled={!changed}
              onClick={event => {
                event.stopPropagation()
                onDiscard()
              }}
            >
              Discard changes
            </Button>

            <Button
              className={cx(style.action, style.save)}
              variant={PRIMARY}
              type="submit"
              disabled={!changed || saving}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </form>
  )
}

const style = {
  wrapper: css`
    display: flex;
    padding: 16px 20px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #00000012;
    background-color: white;
    cursor: pointer;

    @media ${MOBILE_VIEWPORT} {
      min-height: 80px;
      padding: 10px 12px;
      border: 1px solid #0000001a;
      box-shadow: none;
      background-color: transparent;
    }
  `,
  expanded: css`
    cursor: default;
  `,
  header: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media ${MOBILE_VIEWPORT} {
      margin-bottom: 5px;
    }
  `,
  iconWrap: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    margin-right: 20px;
    border-radius: 100%;
    background-color: #dadeff;

    @media ${MOBILE_VIEWPORT} {
      align-self: center;
      width: 31px;
      height: 31px;
      margin-right: 12px;
    }
  `,
  icon: css`
    width: 24px;
    height: 35px;
    color: #5663ff;
    opacity: 0.5;

    @media ${MOBILE_VIEWPORT} {
      width: 14px;
      height: 20px;
    }
  `,
  iconOpaque: css`
    opacity: 1;
  `,
  title: css`
    font-size: 24px;
    font-weight: 500;

    @media ${MOBILE_VIEWPORT} {
      font-size: 18px;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 15px;
    font-weight: 300;

    @media ${MOBILE_VIEWPORT} {
      margin-bottom: 2px;
      font-size: 18px;
    }
  `,
  actions: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
  `,
  action: css`
    padding: 6px 14px;
    font-size: 14px;
    transition: opacity 0.2s;

    :disabled {
      opacity: 0.5;
      cursor: default;
    }
  `,
  save: css`
    margin-left: 8px;
  `
}

export default Card
