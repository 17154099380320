import { create } from 'zustand'
import { axios } from 'frontend-essentials'
import zukeeper from 'zukeeper'

// User data from response
export type UserResponse = {
  accessToken?: string
  refreshToken?: string
  user?: User
  rememberMe?: boolean
}

// Type of User Object
export type User = {
  userId?: string
  email?: string
  isVerified?: boolean
  isServiceProvider?: boolean
  isStaff?: boolean
}

// User data for State
export type UserState = Pick<UserResponse, 'accessToken' | 'refreshToken' | 'rememberMe'> & User

export type UserInfo = {
  businessName: string
  title: string
  description: string
  businessStatus: number
  bankDetails: {
    bankName: string
    branchName: string
    accountNumber: string
  }
  locations: {
    id: number
    city: string
    streetAddress: string
    point: {
      lat: string
      lng: string
    }
    name: string
  }[]
  contact: {
    email: string
    phone: string
  }
  amenities: { id: number; label: string }[]
  openingHours: {
    day: number
    opening: string
    duration: number
  }[]
  socialIntegrations: {
    businessAccount: string
    shareActive: true
  }[]
} | null
// export type UserInfo = {
//   bank_details: any
//   locations: {
//     id: number
//     type: string
//     geometry: {
//       type: string
//       coordinates: [number, number]
//     }
//     properties: {
//       city: string
//       street_address: string
//       country: string
//       state: string
//     }
//   }[]
//   contact: {
//     id: number
//     email: string
//     phone: string
//   }
//   schedule: {
//     opening_hours: any[]
//     special_dates_hours: any[]
//   }
//   host_activities: any[]
//   imagesID: any[]
//   mainImage: string
//   images: any[]
//   businessName: string
//   title: string
//   description: string
//   status: string
//   amenities: { id: number; label: string }[]
//   social_integrations: {
//     businessAccount: string
//     shareActive: boolean
//   }[]
// } | null

export type UserStoreState = {
  user: User
  userInfo: UserInfo
}

export type ActivityDrawerPage = 1 | 2
export type ActivityDrawerType = 'create' | 'edit' | 'calendar'
export type ActivityDrawerPartial = Partial<ActivityDrawer>
export type ActivityDrawer = {
  open: boolean
  activityDrawerType: ActivityDrawerType
  id: number
  title: string
  types: number[]
  instructorName: string
  description: string
  requirements: string
  locationID: number
  repeats: number
  fromDate: Date
  toDate: Date
  image?: string
  totalSpots?: number
  booking: {
    availableSpots: number
    bookingOptionId: number
    bookingOptionLabel: string
    takenSpots: number
    totalSpots: number
  }
  cancellationPolicy?: {
    policyType: number
    penaltyPrice: string
    penaltyTimeId: number
    penaltyTimeLabel: string
    penaltyTimeHrs: number
  }
  onSave?: () => void
  onClose?: () => void
}
// export type ActivityDrawer = {
//   open: boolean
//   activityDrawerType: ActivityDrawerType
//   id: number
//   title: string
//   description: string
//   instructorName: string
//   policyType: number
//   status: string
//   types: number[]
//   requirements: string
//   penaltyPrice: number
//   penaltyTime: number
//   upcoming: string
//   votes: number
//   rating: number
//   activityTimes: string[]
//   image?: string
//   totalSpots?: number
//   schedule?: {
//     activityDates?: {
//       start: string | Date
//       end: string | Date
//     }[]
//     activityTimes: string[]
//     recurring: { id: number; label: string }
//     upcoming: string
//   }
//   booking: {
//     availableSpots: number
//     bookingOptionId: number
//     bookingOptionLabel: string
//     takenSpots: number
//     totalSpots: number
//   }
//   location?: {
//     city: string
//     id: number
//     name: string
//     point: { lat: number; lng: number }
//     streetAddress: string
//     houseNumber: number
//   }
//   cancellationPolicy?: {
//     policyType: number
//     penaltyPrice: string
//     penaltyTimeId: number
//     penaltyTimeLabel: string
//     penaltyTimeHrs: number
//   }
//   onSave?: () => void
//   onClose?: () => void
// }
// export type ActivityDrawer = {
//   open: boolean
//   activityDrawerType: ActivityDrawerType
//   id: string
//   title: string
//   types: { id: number; label: string }[]
//   image: string
//   rating: number
//   votes: number
//   totalSpots: number
//   description: string
//   instructorName: string
//   activityDates: string[]
//   cancellationPolicy: { type: 0 | 1 | 2 }
//   upcoming: string
//   status: string
//   onSave?: () => void
//   onClose?: () => void
// }

export type UserStore = {
  user?: UserState | undefined
  setUser: (args: UserResponse) => void
  userInfo?: UserInfo | undefined
  setUserInfo: (userInfo: UserInfo) => void
  activityDrawer: ActivityDrawer
  setActivityDrawer: (activityDrawer: ActivityDrawerPartial) => void
  cardChanged: boolean
  setCardChanged: (state: boolean) => void
  alertVisible: boolean
  setAlertVisible: (state: boolean) => void
  nominalRerender: boolean
  setNominalRerender: (state: boolean) => void
}

const useStore = create<UserStore>(
  zukeeper(set => ({
    user: JSON.parse(localStorage.user || null) || undefined,
    setUser: ({ accessToken, refreshToken, user, rememberMe }: UserResponse = {}) => {
      if (user) {
        if (rememberMe) localStorage.setItem('user', JSON.stringify({ accessToken, refreshToken, ...user }))

        return set({ user: { accessToken, refreshToken, ...user } })
      }

      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('user')
      set({ user: undefined })
    },

    userInfo: undefined,
    setUserInfo: (userInfo: UserInfo) => {
      set(() => ({
        userInfo: { ...userInfo, amenities: userInfo?.amenities.map(({ id, label }) => ({ label, value: id })) }
        // userInfo: { ...userInfo, amenities: userInfo?.amenities }
      }))
    },

    serviceProviderImages: [],
    setServiceProviderImages: (newImages: any) => set(() => ({ serviceProviderImages: newImages })), // написать типы!!!

    activityDrawer: { open: false },
    setActivityDrawer: (activityDrawer: ActivityDrawer) => set(() => ({ activityDrawer })),

    cardChanged: false,
    setCardChanged: (state: boolean) => set(() => ({ cardChanged: state })),

    alertVisible: false,
    setAlertVisible: (state: boolean) => set(() => ({ alertVisible: state })),

    infoDrawer: false,
    setInfoDrawer: (state: boolean) => set(() => ({ infoDrawer: state })),

    nominalRerender: false,
    setNominalRerender: (state: boolean) => set(() => ({ nominalRerender: state }))
  }))
)

export default useStore
