import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

type Entry = [any, any]

const useChanged = (entries: Entry[]) => {
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    for (const entry of entries) {
      if (!isEqual(...entry)) return setChanged(true)
    }

    setChanged(false)
  }, [...entries.flat()])

  return changed
}

export default useChanged
