import { useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { useMedia } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import Header from 'components/Header'
import Navigation from 'components/Navigation'
import { path } from 'utils/endpoints.constants'

const noNavigationRoutes = ['/edit-photos']
const whiteBackgroundRoutes = [path.calendar]

const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const { mobile } = useMedia({ mobile: MOBILE_VIEWPORT })

  useEffect(() => {
    if (!whiteBackgroundRoutes.includes(pathname)) document.body.style.backgroundColor = '#f5f7fa'

    return () => {
      document.body.style.backgroundColor = 'white'
    }
  }, [mobile, pathname])

  const noNavigation = noNavigationRoutes.includes(pathname)

  return (
    <div className={cx(style.wrapper, { [style.noNavigation]: noNavigation })}>
      <Header />
      <div className={style.container}>
        {!noNavigation && <Navigation />}
        <div className={style.mainContent}>{children}</div>
      </div>
    </div>
  )
}

const style = {
  wrapper: css`
    height: 100%;
    position: relative;
    margin: 60px 0 0 108px;

    @media ${MOBILE_VIEWPORT} {
      margin: 0;
    }
  `,
  noNavigation: css`
    margin: 0;
    margin-top: 60px;

    @media ${MOBILE_VIEWPORT} {
      margin: 0;
    }
  `,
  container: css`
    display: flex;
    height: calc(100% - 60px);
  `,
  mainContent: css`
    width: 100%;
    padding: 30px;

    @media ${MOBILE_VIEWPORT} {
      padding: 0;
      padding-bottom: 60px;
    }
  `
}

export default memo(Layout)
