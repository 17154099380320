import { useState, useRef, useCallback, useLayoutEffect, useEffect, RefObject, ChangeEvent, ReactNode, FC } from 'react'
import { NavLink, useNavigate, useNavigationType, useLocation } from 'react-router-dom'
import { axios, useAxios, useMedia, useObjectState, usePersistedState } from 'frontend-essentials'
import { add } from 'date-fns'
import { css, cx } from '@emotion/css'
import { MOBILE_VIEWPORT } from 'styles/constants'
import useStore, {
  ActivityDrawer,
  ActivityDrawerPage,
  ActivityDrawerType,
  User,
  UserInfo,
  UserStore
} from 'hooks/useStore'
import Select from 'components/common/Select'
import Input from 'components/common/Input'
import Textarea from 'components/common/Textarea'
import Checkbox from 'components/common/Checkbox'
import Radio from 'components/common/Radio'
import Button, { SECONDARY, COMPACT, BORDERLESS } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'

import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { REPETITIONS } from 'utils/constans'
import { SelectChangeEvent } from '@mui/material'
import ActivityDrawerHeader from './ActivityDrawerHeader'
import ActivityDrawerFooter from './ActivityDrawerFooter'
import ActivityDrawerMain from './ActivityDrawerMain'
import ActivityDrawerSecondary from './ActivityDrawerSecondary'
import { scrollToTop } from 'utils/helpers/index.helpers'
import { activityDrawerSizes } from 'utils/constants/sizes.constants'
import moment from 'moment'
import { path } from 'utils/endpoints.constants'
const PENALTY_TIMES = ['3 hours', '6 hours', '12 hours', '24 hours', '48 hours']
const BOOKING_TIMES = ['30 minutes', '1 hour', '2 hours', '6 hours', '12 hours', '24 hours']

const DEFAULT_BOOKING = { totalSpots: 1, bookingOptionId: 0 }

const getFromDate = (): { start: Date; end: Date } => {
  const todayMoment = moment()
  const start = todayMoment.add(1, 'hour').toDate()
  const end = todayMoment.add(1, 'hour').toDate()
  return {
    start,
    end
  }
}

interface Props {
  onClose: () => void
  // setIsOpenActivityDrawer: boolean
  otherProps?: any
}

const ActivityDrawerWrapper: FC<Props> = ({
  onClose,
  // setIsOpenActivityDrawer,
  ...otherProps
}) => {
  const { user, userInfo, activityDrawer, setActivityDrawer, setNominalRerender } = useStore(
    (state: UserStore) => state
  )

  // const { schedule: { activityDates = [] } = {} } = activityDrawer
  // const activityStart = activityDates.length ? new Date(activityDates[0].start) : getFromDate(new Date())
  // const activityEnd = activityDates?.length ? new Date(activityDates[0].end) : undefined
  const formObject = {
    title: activityDrawer.title || '',
    //images: activityDrawer.images || [],
    types: activityDrawer.types || [],
    instructorName: activityDrawer.instructorName || '',
    description: activityDrawer.description || '',
    requirements: activityDrawer.requirements || '',
    locationID: activityDrawer.locationID || 0,
    repeats: activityDrawer.repeats || 0,
    fromDate: activityDrawer.fromDate || getFromDate().start,
    toDate: activityDrawer.toDate || getFromDate().end,
    booking: activityDrawer.booking || DEFAULT_BOOKING,
    cancellationPolicy: {
      policyType: activityDrawer.cancellationPolicy?.policyType || 0,
      penaltyTimeId: activityDrawer.cancellationPolicy?.penaltyTimeId ?? 3,
      penaltyPrice: activityDrawer.cancellationPolicy?.penaltyPrice || 50
    }
  }
  const [
    {
      title,
      //images,
      types,
      instructorName,
      description,
      requirements,
      locationID,
      repeats,
      fromDate,
      toDate,
      booking,
      cancellationPolicy
    },
    setForm
  ] = useObjectState(formObject)

  useEffect(() => {
    setForm(formObject)
  }, [activityDrawer])

  const [formValid, setFormValid] = useState(true)
  const [image, setImage] = useState(null)
  const [activityTypes, setActivityTypes] = useState([])
  const [activityDrawerPage, setActivityDrawerPage] = useState<ActivityDrawerPage>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //const [images, setImages] = useState([])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const ref = useRef(null) as RefObject<HTMLFormElement>

  const imageRef = useRef(null)
  const { mobile } = useMedia({ mobile: MOBILE_VIEWPORT })

  // const [activityId, setActivityId] = useState(null)

  // const { loading: saving, activate: publishActivity } = useAxios({
  //   method: activityDrawer.id ? 'patch' : 'post',
  //   url: activityDrawer.id ? `activity/${activityDrawer.id}` : 'activities',
  //   manual: true,
  //   camelCased: true,
  //   onSuccess: ({ data }) => {
  //     activityDrawer.onSave()
  //     setActivityDrawer({ open: false })
  //     console.log('response', data.response.activity)
  //     if (data && images.length > 0) {
  //       handleUploadImages(data.response.activity)
  //       console.log('images', images)
  //     }
  //   },
  //   onError: ({ error }) => {
  //     console.log(error)
  //   }
  // })

  // SECTION useAxios post patch activityDrawer
  // const { loading: saving, activate: publishActivity } = useAxios({
  //   method: activityDrawer.id ? 'patch' : 'post',
  //   url: activityDrawer.id ? `activity/${activityDrawer.id}` : 'activities',
  //   manual: true,
  //   camelCased: true,

  //   onSuccess: ({ data }) => {
  //     activityDrawer.onSave && activityDrawer.onSave()
  //     setActivityDrawer({ open: false })
  //     console.log('response', data.response.activity)
  //     console.log('image', image)
  //     //setActivityId(data.response.activity.id)
  //     if (data && imageRef.current !== null) {
  //       handleUploadImages(data.response.activity, imageRef.current)
  //       console.log('image', imageRef.current)
  //     }
  //   },
  //   onError: ({ error }) => {
  //     console.log(error)
  //   }
  // })
  // ANCHOR - onSubmit PublishActivity
  // const onSubmit = (event, { publish }) => {
  //   event.preventDefault()
  //   if (!types.length || !title || !instructorName || !locationID) {
  //     setFormValid(false)
  //     return scrollToTop(ref)
  //   }

  //   publishActivity({
  //     data: {
  //       //id_service_provider: userId,
  //       id_user: user?.userId,
  //       title,
  //       description,
  //       instructor_name: instructorName,
  //       types,
  //       schedule: {
  //         start_date: fromDate.toISOString().slice(0, 10),
  //         end_date: add(fromDate, { months: 6 }).toISOString().slice(0, 10),
  //         activity_times: [
  //           {
  //             time_start: fromDate.toISOString().slice(11, 19),
  //             time_finish: toDate.toISOString().slice(11, 19),
  //             idx_day: repeats === 2 ? (fromDate.getDay() === 0 ? 6 : fromDate.getDay() - 1) : undefined
  //           }
  //         ],
  //         recurring: { id: repeats }
  //       },
  //       location: { id: locationID },
  //       booking: { booking_option_id: booking.bookingOptionId, total_spots: booking.totalSpots },
  //       cancellation_policy: {
  //         policy_type: cancellationPolicy.policyType,
  //         penalty_time_id: cancellationPolicy.policyType === 1 ? cancellationPolicy.penaltyTimeId : undefined,
  //         penalty_price: cancellationPolicy.policyType !== 0 ? cancellationPolicy.penaltyPrice : undefined
  //       },
  //       status_id: publish ? 1 : 3
  //     }
  //   })
  // }

  //ANCHOR - new onSubmit
  const onSubmit = async (event, { publish }) => {
    event.preventDefault()
    if (!types.length || !title || !instructorName || !locationID) {
      setFormValid(false)
      return scrollToTop(ref)
    }

    const requestBody = {
      //id_service_provider: userId,
      id_user: user?.userId,
      title,
      description,
      instructor_name: instructorName,
      types,
      schedule: {
        start_date: fromDate.toISOString().slice(0, 10),
        end_date: add(fromDate, { months: 6 }).toISOString().slice(0, 10),
        activity_times: [
          {
            time_start: fromDate.toISOString().slice(11, 19),
            time_finish: toDate.toISOString().slice(11, 19),
            idx_day: repeats === 2 ? (fromDate.getDay() === 0 ? 6 : fromDate.getDay() - 1) : undefined
          }
        ],
        recurring: { id: repeats }
      },
      location: { id: locationID },
      booking: { booking_option_id: booking.bookingOptionId, total_spots: booking.totalSpots },
      cancellation_policy: {
        policy_type: cancellationPolicy.policyType,
        penalty_time_id: cancellationPolicy.policyType === 1 ? cancellationPolicy.penaltyTimeId : undefined,
        penalty_price: cancellationPolicy.policyType !== 0 ? cancellationPolicy.penaltyPrice : undefined
      },
      status_id: publish ? 1 : 3
    }
    try {
      // setIsLoading(true)
      let response
      if (activityDrawer.activityDrawerType === 'create') {
        response = await axios.post(`activities`, requestBody)
      }
      if (activityDrawer.activityDrawerType === 'edit' && !!activityDrawer.id) {
        response = await axios.patch(`activity/${activityDrawer.id}`, requestBody)
      }
      if (response.status === 200) {
        activityDrawer.onSave!()
        setActivityDrawer({ open: false })
        setIsLoading(false)
        if (response && imageRef.current !== null) {
          handleUploadImages(response.data.response.activity, imageRef.current)
          console.log('image', imageRef.current)
        }
      }
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  // !SECTION

  // const handleOnChangeImages = event => {
  //   console.log(event.target.files)
  //   const selectedImages = Array.from(event.target.files)
  //   console.log(selectedImages)
  //   setForm({ images: images.push(...selectedImages) })
  //   console.log(images)
  //   console.log(images.length)
  // }
  const handleOnChangeImages = event => {
    console.log(event.target.files[0])
    const file = event.target.files[0]
    setImage(file)
    imageRef.current = file
  }

  const handleUploadImages = async (activity, image) => {
    const formData = new FormData()
    formData.append('activity_id', activity.id)
    //formData.append('is_main', index === 0 ? 1 : 0)
    // REVIEW '1' was as a number
    formData.append('is_main', '1')
    formData.append('title', 'title')
    formData.append('description', 'description')
    formData.append('file_body', image)
    try {
      console.log(formData)
      const response = await axios.post('upload_activity_image', formData)
      console.log(response.data)
      if (response.data) {
        setNominalRerender(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // get activity types
  useAxios({
    method: 'get',
    url: 'activity-type-options',
    camelCased: true,
    onSuccess: ({ data: { response } }) => {
      setActivityTypes(response.activityTypeOptions.map(({ id, label }) => ({ label, value: id })))
    }
  })

  useLayoutEffect(() => {
    if (mobile && pathname !== path.activities && navigationType === 'POP') {
      navigate('activities')
      onClose()
    }
  }, [mobile, pathname, navigationType, navigate, onClose])

  // useEffect(() => {
  //   if (fromDate.getTime() - toDate.getTime() > 0) setForm({ toDate: getFromDate() })
  // }, [fromDate, toDate])

  const userLocations = useCallback(
    (locationID?: number) => {
      if (locationID) {
        return userInfo?.locations.find(el => el.id === locationID)
      }
      return userInfo?.locations.map(({ streetAddress, city, id }, ind) => ({
        label: `${streetAddress}, ${city}${ind === 0 ? ' (Main)' : ''}`,
        // label: `${streetAddress}, ${city} ${id === locationID ? '(Main)' : ''}`,
        value: id
      }))
    },
    [userInfo]
  )

  const isActivityDrawerMain =
    activityDrawer.activityDrawerType === 'create' ||
    (activityDrawer.activityDrawerType === 'edit' && activityDrawerPage === 1)
  const isActivityDrawerSecond = activityDrawer.activityDrawerType === 'edit' && activityDrawerPage === 2
  const isActivityDrawerCalendar = activityDrawer.activityDrawerType === 'calendar'

  const resetActivityDrawerPage = useCallback(() => {
    setActivityDrawerPage(1)
  }, [activityDrawerPage])

  const handleSetActivityDrawerPage = (page: ActivityDrawerPage) => {
    setActivityDrawerPage(page)
    scrollToTop(ref, true)
  }

  const handleSetActivityDrawerType = (activityDrawerType: ActivityDrawerType) => {
    setActivityDrawer({ ...activityDrawer, activityDrawerType })
  }

  //REVIEW - ActivityDrawer close on changing pathname
  useEffect(() => {
    if (pathname !== path.activities && pathname !== path.calendar) {
      setActivityDrawer({ open: false })
    }
  }, [pathname])

  useEffect(() => {
    setActivityDrawerPage(1)
    scrollToTop(ref)
  }, [activityDrawer.id])
  return (
    <>
      {userInfo && (
        <form className={style.wrapper} ref={ref} onSubmit={event => event.preventDefault()} {...otherProps}>
          <ActivityDrawerHeader
            pathname={pathname}
            activityDrawerPage={activityDrawerPage}
            activityDrawerType={activityDrawer.activityDrawerType}
            resetActivityDrawerPage={resetActivityDrawerPage}
            style={style}
            onClose={onClose}
            formValid={formValid}
            title={title}
            setForm={setForm}
          />

          {isActivityDrawerMain && !isActivityDrawerCalendar && (
            <ActivityDrawerMain
              activityDrawerPage={activityDrawerPage}
              activityDrawerType={activityDrawer.activityDrawerType}
              style={style}
              handleOnChangeImages={handleOnChangeImages}
              image={activityDrawer.activityDrawerType === 'create' ? image : activityDrawer.image}
              activityTypes={activityTypes}
              types={types}
              formValid={formValid}
              setForm={setForm}
              instructorName={instructorName}
              description={description}
              requirements={requirements}
              userLocations={userLocations}
              locationID={locationID}
              booking={booking}
              fromDate={fromDate}
              toDate={toDate}
              repeats={repeats}
              cancellationPolicy={cancellationPolicy}
              pathname={pathname}
              mobile={mobile}
            />
          )}
          {(isActivityDrawerSecond || isActivityDrawerCalendar) && (
            <ActivityDrawerSecondary
              activityDrawerType={activityDrawer.activityDrawerType}
              handleSetActivityDrawerType={handleSetActivityDrawerType}
              activityTypes={activityTypes}
              description={description}
              types={types}
              style={style}
              fromDate={fromDate}
              toDate={toDate}
              setForm={setForm}
              repeats={repeats}
              instructorName={instructorName}
              totalSpots={booking.totalSpots}
              userLocations={userLocations}
              locationID={locationID}
              pathname={pathname}
              mobile={mobile}
            />
          )}

          <ActivityDrawerFooter
            activityDrawerPage={activityDrawerPage}
            activityDrawerType={activityDrawer.activityDrawerType}
            style={style}
            onSubmit={onSubmit}
            saving={isLoading}
            handleSetActivityDrawerPage={handleSetActivityDrawerPage}
          />
        </form>
      )}
    </>
  )
}

const defaultStyles = {
  saveButton: css`
    padding: 12px 20px;

    :disabled {
      opacity: 0.75;
      cursor: default;
    }

    @media ${MOBILE_VIEWPORT} {
      padding: 8px 14px;
    }
  `
}
export const style = {
  wrapper: css`
    z-index: 4;
    position: fixed;
    top: 60px;
    right: 0;
    width: ${activityDrawerSizes.width}px;
    height: calc(100vh - 60px);
    padding: 50px 20px 25px 20px;
    padding-bottom: 0;
    background-color: white;
    box-shadow: -4px 0px 43px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    background-color: white;

    @media ${MOBILE_VIEWPORT} {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
    }
  `,

  wrapperIsClosed: css`
    display: none;

    @media ${MOBILE_VIEWPORT} {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
    }
  `,

  close: css`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 19px;
    height: 19px;
  `,
  titleWrapper: css`
    display: flex;
    align-items: baseline;
    height: auto;
    /* margin-top: 30px; */
    margin-bottom: 15px;
  `,
  title: css`
    width: 250px;
    height: auto;
    border: none;
    border-bottom: 1px solid transparent;
    outline: none;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  `,
  titleError: css`
    border-bottom: 1px solid #ff5631;
  `,
  edit: css`
    width: 18px;
    margin-left: 12px;
  `,
  main: css`
    min-height: 100%;
    /* display: flex;
    flex-direction: column; */
  `,
  date: css`
    margin-top: 15px;
    margin-bottom: 18px;
  `,
  viewCalendar: css`
    display: inline-block;
    margin-top: 10px;
    font-weight: 600;
    text-decoration: none;
    color: #5663ff;
  `,
  images: css`
    /* margin-top: 15px; */
  `,
  photo: css`
    width: 100%;
    margin-top: 15px;
  `,
  subtitle: css`
    font-size: 18px;
    font-weight: 500;
  `,
  field: css`
    margin-top: 15px;
  `,
  checkboxLabel: css`
    font-size: 15px;
  `,
  radioSection: css`
    margin: 20px 0;
  `,
  radioOption: css`
    display: flex;
    align-items: center;
    margin-top: 15px;
  `,
  policyCheckbox: css`
    margin-top: 10px;
  `,
  card: css`
    margin-left: 12px;
    padding: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    transition: opacity 0.2s;
  `,
  cardDisabled: css`
    opacity: 0.65;
    pointer-events: none;
  `,
  radioOptionName: css`
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  `,
  radioOptionInput: css`
    width: 40px;
    margin: 0 5px;
    border: none;
    outline: none;
    font-family: inherit;
    font-weight: bold;
    text-decoration: underline;

    ::-webkit-inner-spin-button {
      opacity: 1;
    }

    @media ${MOBILE_VIEWPORT} {
      width: 28px;
    }
  `,
  radioOptionSelect: css`
    width: auto;
    margin-left: 0;
    color: #3b48e7;
    cursor: pointer;

    @media ${MOBILE_VIEWPORT} {
      width: auto;
    }
  `,
  save: css`
    z-index: 2;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 85px;
    background-color: white;

    @media ${MOBILE_VIEWPORT} {
      height: 60px;
    }
  `,
  separator: css`
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 1px;
    border: none;
    background-color: #d9d9d9;
  `,
  saveButton: css`
    ${defaultStyles.saveButton}
  `,
  buttonIcon: css`
    ${defaultStyles.saveButton};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    svg path {
      fill: #000;
    }
  `
}
// style.wrapperIsClosed = css`
//   ${style.wrapper};
//   /* translate: -50% 0; */
//   right: 100px;
// `
export default ActivityDrawerWrapper
