import { useState, useRef, useEffect } from 'react'
import { axios, useAxios, useMedia } from 'frontend-essentials'
import { css, cx } from '@emotion/css'

import useStore from 'hooks/useStore'
import Button, { COMPACT } from 'components/common/Button'
import Photo from 'components/Photo'
import { MOBILE_VIEWPORT } from 'styles/constants'
import EditPhotoDrawer from 'components/Photo/EditPhotoDrawer'
import { set } from 'date-fns'

const EditPhotos = () => {
  const { businessName } = useStore(state => state.userInfo) || {}
  const user = useStore(state => state.user)
  const [photoDrawer, setPhotoDrawer] = useState(false)
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const { serviceProviderImages, setServiceProviderImages } = useStore(state => state)
  console.log(serviceProviderImages)
  const [imgID, setImgID] = useState([])
  const [loadedImages, setLoadedImages] = useState([])
  const inputRef = useRef()
  const { mobile } = useMedia({ mobile: MOBILE_VIEWPORT })

  const addSelectedPhoto = (id, image, description) => {
    setSelectedPhotos([...selectedPhotos, { id, image, description }])
  }

  const removeSelectedPhoto = id => {
    setSelectedPhotos(selectedPhotos.filter(({ id: selectedPhotoID }) => selectedPhotoID !== id))
  }

  const onUploadPhotos = async event => {
    const images = Array.from(event.target.files)
    for (let [index, image] of images.entries()) {
      console.log(image)
      const formData = new FormData()
      formData.append('service_provider_id', user.userId)
      formData.append('is_main', index === 0 ? 1 : 0)
      formData.append('title', 'Some Title')
      formData.append('description', 'Some Description')
      formData.append('file_body', image)
      try {
        console.log(formData)
        const response = await axios.post('/profile-images', formData)
        console.log(response)
        console.log(response.data)
        if (response.status === 200) {
          try {
            const response = await axios.get('/image_id', {
              params: {
                object_type: 1,
                object_id: user.userId
              }
            })
            if (response.data) {
              const responseData = response.data?.response.image_ids
              console.log(responseData)
              setImgID(responseData)
              // запрос га получение по айдишникам картинок
              const newServiceProviderImages = []
              for (const dataId of responseData) {
                try {
                  const response = await axios.get('image_body', {
                    params: {
                      image_id: dataId,
                      object_type: 1
                    }
                  })

                  setLoadedImages(loadedImages => [...loadedImages, response.data.response.image.image_body])
                  newServiceProviderImages.push(response.data.response.image)
                } catch (e) {
                  console.log(e)
                }
              }
              setServiceProviderImages(newServiceProviderImages)
            } else {
              setImgID([])
            }
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const deleteImageFromServer = async () => {
    for (let image of selectedPhotos) {
      try {
        await axios.delete('/image_body', {
          data: {
            id: image.id,
            object_type: 1
          }
        })
        const updatedImages = [...serviceProviderImages].filter(
          img => !selectedPhotos.some(photo => photo.id === img.id)
        )
        setServiceProviderImages(updatedImages)
        setSelectedPhotos([])
        console.log(serviceProviderImages)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleClickedImage = clickedImage => {
    setSelectedImage(clickedImage)
    setPhotoDrawer(true)
  }
  const handleCloseDrawer = () => {
    setPhotoDrawer(false)
  }

  const handleUpdateImages = async imgID => {
    try {
      const response = await axios.get('/image_body', {
        params: {
          image_id: imgID,
          object_type: 1
        }
      })
      console.log(response)
      if (response.status === 200) {
        const updatedServiceProviderImages = serviceProviderImages.map(image => {
          if (image.id === imgID) {
            return response.data.response.image
          } else {
            return image
          }
        })
        setServiceProviderImages(updatedServiceProviderImages)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateCroppedImages = async () => {
    try {
      const response = await axios.get('/image_id', {
        params: {
          object_type: 1,
          object_id: user.userId
        }
      })
      if (response.data) {
        const responseData = response.data?.response.image_ids
        console.log(responseData)
        setImgID(responseData)
        // запрос га получение по айдишникам картинок
        const newServiceProviderImages = []
        for (const dataId of responseData) {
          try {
            const response = await axios.get('image_body', {
              params: {
                image_id: dataId,
                object_type: 1
              }
            })

            setLoadedImages(loadedImages => [...loadedImages, response.data.response.image.image_body])
            newServiceProviderImages.push(response.data.response.image)
          } catch (e) {
            console.log(e)
          }
        }
        setServiceProviderImages(newServiceProviderImages)
      } else {
        setImgID([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <h1 className={style.title}>{businessName} photos</h1>
      <div className={style.main}>
        <div className="items-center">
          {!mobile && selectedPhotos.length > 0 && (
            <span className={style.selectedCount}>
              {selectedPhotos.length} image{selectedPhotos.length > 1 ? 's' : ''} selected
            </span>
          )}

          <button
            className={cx(style.action, style.select, { [style.undelimited]: mobile || !selectedPhotos.length })}
            onClick={() => setSelectedPhotos(serviceProviderImages)}
          >
            Select all
          </button>

          <button className={cx(style.action, style.select)} onClick={() => setSelectedPhotos([])}>
            Deselect all
          </button>

          <button className={cx(style.action, style.delete)} onClick={deleteImageFromServer}>
            Delete {mobile && selectedPhotos.length ? `(${selectedPhotos.length})` : ''}
          </button>

          <Button className={style.add} variant={COMPACT} onClick={() => inputRef.current.click()}>
            Add photos
          </Button>
          <input className="hidden" ref={inputRef} type="file" accept="image/*" multiple onChange={onUploadPhotos} />
        </div>

        <div className={style.grid}>
          {serviceProviderImages?.map(image => (
            //(console.log(image),
            <Photo
              key={image.id}
              src={image.image_body}
              description={image.description}
              selected={!!selectedPhotos.find(({ id }) => id === image.id)}
              onSelect={selected => {
                if (selected) {
                  addSelectedPhoto(image.id, image.image_body, image.description)
                } else {
                  removeSelectedPhoto(image.id)
                }
              }}
              onClick={event => {
                handleClickedImage(image)
              }}
            />
            //)
          ))}
        </div>
      </div>
      {photoDrawer && (
        <EditPhotoDrawer image={selectedImage} onClose={handleCloseDrawer} handleUpdateImages={handleUpdateImages} handleUpdateCroppedImages={handleUpdateCroppedImages} />
      )}
    </div>
  )
}

const style = {
  title: css`
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;

    @media ${MOBILE_VIEWPORT} {
      margin: 10px 20px 0;
    }
  `,

  main: css`
    margin-top: 30px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 #00000012;
    font-size: 15px;
    background-color: white;
  `,
  selectedCount: css`
    height: 16px;
    margin-right: 20px;
  `,
  action: css`
    padding: 0 20px;
    border-left: 1px solid #b8b8b8;

    @media ${MOBILE_VIEWPORT} {
      padding: 0 10px;

      :first-child {
        padding-left: 0;
      }
    }
  `,
  undelimited: css`
    border: none;
  `,
  select: css`
    color: #5663ff;
  `,
  delete: css`
    color: #ee2a2a;
  `,
  add: css`
    margin-left: auto;
  `,
  grid: css`
    margin-top: 20px;
    display: grid;
    grid-column: span 1;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 24px;
    justify-content: space-between;
    width: 100%;

    @media ${MOBILE_VIEWPORT} {
      grid-template-columns: 1fr;
    }
  `
}

export default EditPhotos
