import { useState, useEffect, memo } from 'react'
import { css } from '@emotion/css'

import { MOBILE_VIEWPORT } from 'styles/constants'
import useChanged from 'hooks/useChanged'
import Card from 'components/common/Card'
import Input from 'components/common/Input'
import ContactIcon from 'images/contact.svg'
import useValidation from 'hooks/useValidation'

const Contact = ({
  className,
  userExpanded,
  saving,
  phone: initialPhone = '',
  email: initialEmail = '',
  expand,
  collapse,
  setCardChanged,
  updateProfile
}) => {
  const [phone, setPhone] = useState(initialPhone)
  const [email, setEmail] = useState(initialEmail)

  const { isValid: phoneIsValid, setValidation: setPhoneValidation } = useValidation('tel', phone)
  const { isValid: emailIsValid, setValidation: setEmailValidation } = useValidation('email', email)

  const changed = useChanged([
    [phone, initialPhone],
    [email, initialEmail]
  ])

  const expanded = userExpanded || !initialPhone || !initialEmail

  useEffect(() => setCardChanged(changed), [changed])

  const onDiscard = () => {
    setPhone(initialPhone)
    setEmail(initialEmail)
    setCardChanged(false)
    collapse()
  }

  const handleOnSave = () => {
    if (phoneIsValid && emailIsValid) {
      updateProfile({ data: { contact: { phone, email } } })
    }
  }

  return (
    <Card
      className={className}
      Icon={ContactIcon}
      title="Contact information"
      expanded={expanded}
      changed={changed}
      saving={saving}
      onClick={expand}
      onSave={handleOnSave}
      onDiscard={onDiscard}
    >
      {expanded ? (
        <>
          <div className="flex-wrap">
            <div className={style.section}>
              <Input
                type="tel"
                label="Phone number"
                value={phone}
                required
                onChange={event => setPhone(event.target.value)}
                onBlur={setPhoneValidation}
              />
              {!phoneIsValid && (
                <p className={`${style.section} ${style.validation}`}>
                  Phone number is invalid
                  <span className={style.description}> e.g. +972543210123, 054-3210123</span>
                </p>
              )}
            </div>
            <div className={`${style.section} ${!emailIsValid && style.inputInvalid}`}>
              <Input
                type="email"
                label="Email address"
                value={email}
                required
                onChange={event => setEmail(event.target.value)}
                onBlur={setEmailValidation}
              />
              {!emailIsValid && (
                <p className={`${style.section} ${style.validation}`}>
                  Email is invalid
                  <span className={style.description}> e.g. example@example.com</span>
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex-col">
          <span>{phone}</span>
          <span>{email}</span>
        </div>
      )}
    </Card>
  )
}

const style = {
  section: css`
    width: 100%;
    max-width: 300px;
    margin: 0 10px 5px 0;

    @media ${MOBILE_VIEWPORT} {
      max-width: unset;
      margin-right: 0;
    }
  `,
  validation: css`
    color: red;
    font-weight: 500;
    margin-top: 5px;
  `,
  inputInvalid: css`
    input {
      border-color: red;
    }
  `,
  description: css`
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  `
}

export default memo(Contact)
