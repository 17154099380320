import { useId, FC } from 'react'
import { css, cx } from '@emotion/css'
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material'

export type CheckboxProps = Omit<MuiCheckboxProps, 'onChange'> & {
  labelClassName?: string
  label: string
  onChange: (checked: boolean) => void
}

const Checkbox: FC<CheckboxProps> = ({ className, labelClassName, label, onChange, ...otherProps }) => {
  const id = useId()

  return (
    <div className={cx('items-center', className)}>
      <MuiCheckbox className={style.checkbox} id={id} onChange={(_, checked) => onChange(checked)} {...otherProps} />

      <label className={cx(style.label, labelClassName)} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

const style = {
  label: css`
    margin-left: 8px;
  `,
  checkbox: css`
    padding: 0;
    color: #3b48e7;

    &.Mui-checked {
      color: #3b48e7;
    }

    &.Mui-disabled {
      color: #3b48e7;
      opacity: 0.75;
    }
  `
}

export default Checkbox
