import { useId, FC } from 'react'
import { css, cx } from '@emotion/css'
import { Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from '@mui/material'

import Label from './Label'
import Chip from 'components/common/Chip'

export type SelectOption = {
  label: string
  value: any
}
export type OptionButton = (value?: any) => any

export type SelectProps = MuiSelectProps & {
  label?: string
  options: SelectOption[]
  optionRow?: OptionButton
}

const Select: FC<SelectProps> = ({ className, label, optionRow, options, multiple, ...otherProps }) => {
  const id = useId()
  return (
    <div className={cx(style.wrapper, className)}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <MuiSelect
        className={style.select}
        id={id}
        multiple={multiple}
        renderValue={(selected: any) =>
          options.length > 0 &&
          (multiple ? (
            <div>
              {selected.map(value => (
                <Chip key={value} label={(options.find(option => option.value === value) || {}).label} />
              ))}
            </div>
          ) : (
            <div key={selected}>{(options.find(option => option.value === selected) || {}).label}</div>
          ))
        }
        {...otherProps}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
        {/* {optionRow && <button onClick={optionRow.callback}>{optionRow.label}</button>} */}
        {optionRow && optionRow()}
      </MuiSelect>
    </div>
  )
}

const style = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    max-width: 100%;
  `,
  select: css`
    height: 42px;

    :hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #adaabf;
      }
    }

    :active {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #5663ff !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #adaabf;
      border-radius: 10px;
      box-shadow: 0px 1px 2px 0px #322b5f14;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #adaabf;
        box-shadow: 0px 0px 0px 3px #ded9fb;
      }
    }
  `
}

export default Select
