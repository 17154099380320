import { useContext, cloneElement, FC } from 'react'
import { css, cx } from '@emotion/css'
import { MenuItem, MenuItemProps } from '@mui/material'

import CheckmarkIcon from 'images/checkmark.svg'

export type ActionProps = MenuItemProps & {
  Icon?: any
  description?: string
  selected?: boolean
}

const Action: FC<ActionProps> = ({ className, Icon, description, selected, onClick, children, ...otherProps }) => {
  return (
    <MenuItem
      className={cx(style.wrapper, { 'cursor-pointer': !selected, 'pointer-events-none': selected }, className)}
      onClick={event => {
        onClick?.(event)
      }}
      {...otherProps}
    >
      {Icon && cloneElement(Icon, { className: cx(style.icon, Icon.props.className) })}

      <div className="flex-col">
        {children}

        {description && <span className={style.description}>{description}</span>}
      </div>

      {selected && <CheckmarkIcon className={style.checkmark} />}
    </MenuItem>
  )
}

const style = {
  wrapper: css`
    &.MuiMenuItem-root {
      display: flex;
      align-items: flex-start;
      min-height: 0;
      padding: 0;
      line-height: normal;
      letter-spacing: normal;
      font-family: inherit;
      font-size: 18px;
      font-weight: 500;
      user-select: none;

      :not(:first-child) {
        margin-top: 15px;
      }

      :hover {
        opacity: 0.75;
      }
    }
  `,
  icon: css`
    width: 20px;
    margin: 1px 10px 0 0;
  `,
  description: css`
    font-size: 15px;
    font-weight: 300;
    color: #5c5c5c;
    user-select: none;
  `,
  checkmark: css`
    width: 18px;
    margin: 5px 0 0 auto;
  `
}

export default Action
