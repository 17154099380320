import { useEffect, useState, FocusEvent } from 'react'

type TValidation = 'tel' | 'email'

// const numberPattern = /^[\d-]+$/
// const phoneNumberPattern = /^05[0-9]-[0-9]{7}$/
const phoneNumberPattern = /^[-+0-9]+$/
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const validateString = (typeValidation: TValidation, value: string) => {
  switch (typeValidation) {
    case 'tel':
      return phoneNumberPattern.test(value)
    case 'email': {
      return emailPattern.test(value)
    }
    default:
      return true
  }
}

// const filterInput = (value: string, setValue: (value: string) => void) => {
//   const filteredText = value.replace(/[^0-9-]/g, '')
//   const formattedText = filteredText.replace(/(\d{2})(\d{3})(\d{7})/, '05*$1-$2-$3')
//   setValue(formattedText)
// }

const useValidation = (typeValidation: TValidation, value: string) => {
  // const [filteredValue, setFilteredValue] = useState(value)
  const [isValid, setIsValid] = useState(true)
  const [focusout, setFocusout] = useState(true)
  // const isValid = useRef(validateString(typeValidation, value))

  const setValidation = () => {
    // if (typeValidation === 'tel') {
    //   filterInput(value, setFilteredValue)
    // }
    setIsValid(validateString(typeValidation, value))
    setFocusout(false)
  }

  useEffect(() => {
    if (!focusout || focusout === undefined) {
      setValidation()
    }
  }, [value])

  return { isValid, setValidation }
}

export default useValidation
