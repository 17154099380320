import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled
} from '@mui/material'
import Photo from 'components/Photo'
import { useEffect, useId, useState } from 'react'
import { MOBILE_VIEWPORT } from 'styles/constants'
import CloseIcon from 'images/close.svg'
import { IMAGE_EDIT_OPTIONS } from 'utils/constans'
import Label from 'components/common/Label'
import { axios } from 'frontend-essentials'
import { el } from 'date-fns/locale'
import ImageCropDialog from './ImageCropDialog'
import { ISelectedImage } from 'types/types'
import { set } from 'date-fns'
import useStore from 'hooks/useStore'

const BoxDrawer = styled('div')`
  z-index: 4;
  position: fixed;
  top: 60px;
  right: 0;
  width: 470px;
  height: calc(100vh - 60px);
  padding: 25px 20px;
  padding-bottom: 0;
  background-color: white;
  box-shadow: -4px 0px 43px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  background-color: white;
  display: flex;
  //justify-content: center;
  flex-direction: column;

  @media ${MOBILE_VIEWPORT} {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }
`

const CloseButton = styled('button')`
  width: 19px;
  height: 19px;
  margin-left: auto;
  margin-bottom: 20px;
`

const PhotoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  /* height: 80vh; */
  img {
    width: 100%;
    height: 100%;
  }
  margin-bottom: 20px;
`
const SelectWrapper = styled(FormControl)`
  &.MuiFormControl-root:not(:focus) .MuiInputLabel-animated[data-shrink='false'] {
    transform: translate(14px, 0px) scale(1);
  }
`
const CustomSelect = styled(Select)`
  /* width: 142px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 100px;
  width: 142px;
  height: 28px;
`
const CustomInputLable = styled(InputLabel)`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextFieldsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const CustomTextField = styled(TextField)`
  border-radius: 10px;
  margin-bottom: 40px;
  height: 42px;
`
const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`

const MyWrapper = styled('div')`
  display: flex;
  justify-content: end;
`

interface Props {
  image: any
  onClose: () => void
  handleUpdateImages: (imageID: number) => void
  handleUpdateCroppedImages: () => void
}

const EditPhotoDrawer: React.FC<Props> = ({ onClose, image, handleUpdateImages, handleUpdateCroppedImages }) => {
  const id = useId()
  const user = useStore(state => state.user)
  const [title, setTitle] = useState(image.title)
  const [description, setDescription] = useState(image.description)
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const [dialog, setDialog] = useState(false)
  const [thisImage, setThisImage] = useState(image)
  const [selectedImage, setSelectedImage] = useState<null | ISelectedImage>(
    image
      ? {
          selectedImageId: image.id,
          selectedImageBody: image.image_body,
          selectedImageUrl: image.image_body,
          selectedCrop: null,
          selectedZoom: null,
          selectedAspect: null
        }
      : null
  )

  const handleSaveChanges = async () => {
    try {
      if (thisImage.croppedImageUrl) {
        const data = await axios.delete('/image_body', {
          data: {
            id: image.id,
            object_type: 1
          }
        })
        if (data.status === 200) {
          const base64WithoutPrefix = thisImage.croppedImageUrl.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
          const binaryData = atob(base64WithoutPrefix)
          const byteArray = new Uint8Array(binaryData.length)
          for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i)
          }
          const formData = new FormData()
          formData.append('service_provider_id', user?.userId || '')
          formData.append('is_main', '0') // надо добавить возможность изменения главной фотки!!!!
          formData.append('title', title)
          formData.append('description', description)
          formData.append('file_body', new Blob([byteArray], { type: 'image/png' }))
          try {
            console.log(formData)
            const response = await axios.post('/profile-images', formData)
            console.log(response)
            console.log(response.data)
            if (response.status === 200) {
              onClose()
              handleUpdateCroppedImages()
            }
          } catch (error) {
            console.error(error)
          }
        }
      } else {
        const data = {
          id: image.id,
          object_type: 1,
          title: title,
          is_main: 0,
          description: description
        }
        const response = await axios.patch('/image_body', data)
        if (response.status === 200) {
          onClose()
          handleUpdateImages(image.id)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOptionChange = (selectedOption: number) => {
    setSelectedOption(selectedOption)
    if (selectedOption === 0) {
      console.log('Make cover image')
    } else if (selectedOption === 1) {
      console.log('Crop image')
      setDialog(true)
    } else if (selectedOption === 2) {
      console.log('Replace image')
    } else if (selectedOption === 3) {
      console.log('Delete image')
    }

    setSelectedOption(null)
  }

  const toggleDrawer = () => {
    onClose()
  }

  const setCroppedImageFor = async (imageId: any, croppedImageUrl?: any, crop?: any, zoom?: any, aspect?: any) => {
    const newImage = {
      ...thisImage,
      croppedImageUrl: croppedImageUrl,
      crop: crop,
      zoom: zoom,
      aspect: aspect
    }
    setThisImage(newImage)
    setDialog(false)
  }

  const resetImage = imageId => {
    setCroppedImageFor(imageId)
  }

  const onCancel = () => {
    setSelectedImage(selectedImage)
    setDialog(false)
  }

  return (
    <BoxDrawer>
      <CloseButton>
        <CloseIcon onClick={toggleDrawer} />
      </CloseButton>
      <PhotoWrapper>
        {dialog ? (
          <ImageCropDialog
            imageId={selectedImage?.selectedImageId ? selectedImage?.selectedImageId : selectedImage?.id}
            imageUrl={
              selectedImage?.selectedImageUrl ? selectedImage?.selectedImageUrl : selectedImage?.croppedImageUrl
            }
            cropInit={selectedImage?.selectedCrop}
            zoomInit={selectedImage?.selectedZoom}
            aspectInit={selectedImage?.selectedAspect}
            onCancel={onCancel}
            setCroppedImageFor={setCroppedImageFor}
            resetImage={resetImage}
          />
        ) : null}
        <img
          src={thisImage.croppedImageUrl ? thisImage.croppedImageUrl : `data:image/png;base64, ${image.image_body}`}
          alt=""
        />
      </PhotoWrapper>
      <MyWrapper>
        <SelectWrapper>
          <CustomInputLable id="label">Edit Image</CustomInputLable>
          <CustomSelect
            labelId="label"
            label="Edit Image"
            value={selectedOption}
            onChange={event => handleOptionChange(event.target.value as number)}
          >
            {IMAGE_EDIT_OPTIONS.map(option => (
              <MenuItem style={{ cursor: 'pointer' }} key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </SelectWrapper>
      </MyWrapper>
      <TextFieldsWrapper>
        <Label htmlFor={id}>Title</Label>
        <CustomTextField
          id={id}
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value)
          }}
        />
        <Label htmlFor={id}>Description</Label>
        <CustomTextField
          id={id}
          value={description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value)
          }}
        />
      </TextFieldsWrapper>

      <Divider style={{ marginTop: '50px' }} />
      <ButtonWrapper>
        <Button
          style={{ color: 'black', fontSize: '15px', textTransform: 'none' }}
          variant="text"
          onClick={toggleDrawer}
        >
          Discard Changes
        </Button>
        <Button style={{ fontSize: '18px', textTransform: 'none' }} variant="outlined" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </ButtonWrapper>
    </BoxDrawer>
  )
}

export default EditPhotoDrawer
