import { css, cx } from '@emotion/css'
import { styled } from '@mui/material'

import Checkbox from 'components/common/Checkbox'
import { useEffect, useState } from 'react'



const Photo = ({ className, src, description, selected, onSelect, ...otherProps }) => {
  return (
    (
      <div
        style={{
          backgroundImage: `url(data:image/png;base64,${src})`
          //backgroundImage: `url(https://www.w3schools.com/css/img_5terre.jpg)`
        }}
        className={cx(style.wrapper, className)}
        title={description}
        {...otherProps}
      >
        <Checkbox className={style.checkmark} checked={selected} onChange={onSelect} onClick={(e)=> e.stopPropagation()} />
      </div>
    )
  )
}

const style = {
  wrapper: css`
    position: relative;
    display: inline-block;
    height: 316px;
    background-size: contain;
    background-position: center;
    background-color: #c4c4c4;
    background-repeat: no-repeat;
    cursor: pointer;
  `,
  checkmark: css`
    position: absolute;
    top: 8px;
    left: 8px;
  `
}

export default Photo
