import moment from 'moment'
import { ISchedule } from 'types/types'

export const getScheduleTimes = (schedule: ISchedule) => {
  const format = 'YYYY-MM-DDThh:mm:ssZ'
  // const fromDate = moment(schedule.activityDates[0].start, format).toDate()
  const fromDate = moment(schedule.upcoming, format).toDate()
  let toDate
  if (schedule.activityTimes[0].includes(',')) {
    const [repeats, timeRange] = schedule.activityTimes[0].split(', ')
    const [startTime, endTime] = timeRange.split('-')
    toDate = moment(endTime, 'hh:mm:ssZ').toDate()
  } else {
    const [startTime, endTime] = schedule.activityTimes[0].split('-')
    toDate = moment(endTime, 'hh:mm:ssZ').toDate()
  }
  return {
    fromDate,
    toDate
  }
}
