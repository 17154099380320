import { injectGlobal } from '@emotion/css'

injectGlobal`
	.relative {
		position: relative;
	}

	.w-full {
		width: 100%;
	}

	.max-w-full {
		max-width: 100%;
	}

	.hidden {
		display: none;
	}

	.invisible {
		visibility: hidden;
	}

	.flex {
		display: flex;
	}

	.flex-col {
		display: flex;
		flex-direction: column;
	}

	.flex-wrap {
		display: flex;
		flex-wrap: wrap;
	}

	.flex-1 {
		flex: 1;
	}

	.flex-2 {
		flex: 2;
	}

	.flex-3 {
		flex: 3;
	}

	.justify-between {
		display: flex;
		justify-content: space-between;
	}

	.justify-end {
		display: flex;
		justify-content: flex-end;
	}

	.items-center {
		display: flex;
		align-items: center;
	}

	.items-end {
		display: flex;
		align-items: flex-end;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.pointer-events-none {
		pointer-events: none;
	}

	.text-center {
		text-align: center;
	}

	.grayscale {
		filter: grayscale(100%);
	}
//=============================Calendar==========================================
  .rbc-event {
    padding: 0;
    background-color: unset;

    .event {
      border-radius: 3px;
      padding: 5px 10px;
    }
  }

  .rbc-toolbar-label {
    display: flex;
    font-size: 21px;
    font-weight: 500;
    padding: 0 25px;
  }

  .rbc-btn-group {
    :first-of-type {
      button {
        :nth-child(1) {
          border-radius: 3px;
        }

        :nth-child(2) {
          margin-left: 14px;
          position: relative;
          border: none;
          visibility: hidden;
          width: 5px;

          ::after {
            position: absolute;
            left: 50%;
            visibility: visible;
            font-size: 14px;
            cursor: pointer;
            content: 'ᐸ';
          }
        }

        :nth-child(3) {
          position: relative;
          border: none;
          visibility: hidden;
          width: 5px;

          ::after {
            position: absolute;
            left: 50%;
            visibility: visible;
            font-size: 14px;
            cursor: pointer;
            content: 'ᐳ';
          }
        }
      }
    }
  }
  //=======================================================================
`
