import { create } from '@mui/material/styles/createTransitions'
import Button, { BORDERLESS, SECONDARY } from 'components/common/Button'
import { ActivityDrawerPage, ActivityDrawerType } from 'hooks/useStore'
import PrevIcon from 'images/back.svg'
import React, { FC, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { path } from 'utils/endpoints.constants'

interface Props {
  style: {
    [key: string]: string
  }
  onSubmit: (...values: any) => void
  saving: boolean
  activityDrawerPage: ActivityDrawerPage
  activityDrawerType: ActivityDrawerType
  handleSetActivityDrawerPage: (page: ActivityDrawerPage) => void
}

const ActivityDrawerFooter: FC<Props> = ({
  style,
  onSubmit,
  saving,
  activityDrawerType,
  activityDrawerPage,
  handleSetActivityDrawerPage
}) => {
  // TODO switch (ADType & ADPage)
  const activityDrawerFooterState = (value: 'create' | 'edit_start' | 'edit_end' | 'calendar') => {
    switch (value) {
      case 'create':
        return activityDrawerType === 'create'
      case 'edit_start':
        return activityDrawerType === 'edit' && activityDrawerPage === 1
      case 'edit_end':
        return activityDrawerType === 'edit' && activityDrawerPage === 2
      case 'calendar':
        return activityDrawerType === 'calendar'
      default:
        return
    }
  }

  return (
    <div className={style.save}>
      <hr className={style.separator} />

      {activityDrawerFooterState('create') && (
        <div className="justify-between w-full">
          <Button
            className={style.saveButton}
            variant={BORDERLESS}
            disabled={saving}
            onClick={event => {
              onSubmit(event, { publish: false })
            }}
          >
            Save draft
          </Button>

          <Button
            className={style.saveButton}
            variant={SECONDARY}
            disabled={saving}
            onClick={event => {
              onSubmit(event, { publish: true })
            }}
          >
            Save & activate
          </Button>
        </div>
      )}
      {activityDrawerFooterState('edit_start') && (
        // <div className="justify-between w-full">
        <Button
          className={style.saveButton}
          variant={SECONDARY}
          disabled={saving}
          onClick={() => {
            handleSetActivityDrawerPage(2)
          }}
        >
          Next
        </Button>
        // </div>
      )}
      {(activityDrawerFooterState('edit_end') || activityDrawerFooterState('calendar')) && (
        <div className={`justify-${activityDrawerFooterState('calendar') ? 'end' : 'between'} w-full`}>
          {!activityDrawerFooterState('calendar') && (
            <Button
              className={style.buttonIcon}
              variant={BORDERLESS}
              disabled={saving}
              onClick={() => {
                handleSetActivityDrawerPage(1)
              }}
            >
              <PrevIcon width={13.33} height={13.33} />
              Back
            </Button>
          )}

          <Button
            className={style.saveButton}
            variant={SECONDARY}
            disabled={saving}
            onClick={event => {
              onSubmit(event, { publish: true })
            }}
          >
            Save & publish
          </Button>
        </div>
      )}
    </div>
  )
}

export default ActivityDrawerFooter
