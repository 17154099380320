/*global FB*/
const useFacebookSDK = () => {
  function statusChangeCallback(response) {
    // Called with the results from FB.getLoginStatus().
    // console.log(response) // The current login status of the person.
    // if (response.status === 'connected') {
    console.log('Status connected')
    return response.status
    // Logged into your webpage and Facebook.
    // } else {
    // console.log('unlogged')
    // Not logged into your webpage or we are unable to tell.
    // document.getElementById('status').innerHTML = 'Please log ' + 'into this webpage.'
    // }
  }

  function checkLoginState() {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus(function (response) {
        // See the onlogin handler
        const res = statusChangeCallback(response)
        if (response.status === 'connected') {
          resolve(res)
        } else {
          reject(new Error('Check login failed'))
        }
      })
    })
  }

  async function login() {
    return new Promise((resolve, reject) => {
      FB.login(function (response) {
        console.log('facebook login')
        if (response.authResponse) {
          resolve(response.authResponse)
        } else {
          reject(new Error('Facebook login failed'))
        }
      })
    })
  }

  function logout() {
    FB.logout(function (response) {
      console.log('facebook logout')
      console.log(response)
      // Person is now logged out
    })
  }

  return { checkLoginState, login, logout }
}

export default useFacebookSDK
