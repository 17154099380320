import { useState, useCallback, useEffect, useMemo } from 'react'
import { Media, axios, useAxios } from 'frontend-essentials'
import { css } from '@emotion/css'

import useStore from 'hooks/useStore'
import HomeHeader from 'components/HomeHeader'
import HomeMenu from 'components/HomeMenu'
import Photos from 'components/HomeCards/Photos'
import Address from 'components/HomeCards/Address'
import Contact from 'components/HomeCards/Contact'
import Description from 'components/HomeCards/Description'
import Amenities from 'components/HomeCards/Amenities'
import Social from 'components/HomeCards/Social'
import Alert from 'components/common/Alert'
import { MOBILE_VIEWPORT } from 'styles/constants'
import { set } from 'date-fns'
import { serviceProvider } from 'utils/endpoints.constants'

const Home = () => {
  const { user, userInfo, setUserInfo, cardChanged, setCardChanged, alertVisible, setAlertVisible } = useStore(
    state => state
  )
  const [expandedCard, setExpandedCard] = useState('')
  const [imgID, setImgID] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/image_id', {
          params: {
            object_type: 1,
            object_id: user.userId
          }
        })
        if (response.data) {
          const responseData = response.data?.response.image_ids
          console.log(responseData)
          setImgID(responseData)
        } else {
          setImgID([])
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  // const { data = {} } = useAxios({
  //   method: 'get',
  //   url: '/image_id',
  //   params: {
  //     object_type: 1,
  //     object_id: user.userId
  //   },
  //   camelCased: true,
  //   onSuccess: ({ data }) => {
  //     console.log(data.response.imageIds)
  //   },
  //   onError: ({ error }) => {
  //     console.log(error)
  //   }
  // })

  const { loading: updatingProfile, activate } = useAxios({
    method: 'patch',
    manual: true,
    camelCased: true,
    onSuccess: ({ data: { response } }) => {
      setUserInfo(response)
      setExpandedCard('')
      setCardChanged(false)
    }
  })
  const updateProfile = useCallback(
    request => activate({ url: `${serviceProvider}/${user?.userId}`, ...request }),
    [activate, user?.userId]
  )

  useEffect(() => {
    if (!cardChanged) setAlertVisible(false)
  }, [cardChanged])

  const getCommonProps = type => ({
    className: style.card,
    userExpanded: expandedCard === type,
    saving: updatingProfile && expandedCard === type,
    expand: () => (cardChanged && expandedCard !== type ? setAlertVisible(true) : setExpandedCard(type)),
    collapse: () => setExpandedCard(''),
    setCardChanged,
    updateProfile
  })

  if (!userInfo) return null

  const { businessName, businessStatus, locations, contact, description, amenities, socialIntegrations } = userInfo
  return (
    <div>
      <HomeHeader
        className={style.header}
        businessName={businessName}
        businessStatus={businessStatus}
        updateProfile={updateProfile}
      />

      <div className={style.cards}>
        <Media query={MOBILE_VIEWPORT}>
          <HomeMenu className={style.status} businessStatus={businessStatus} updateProfile={updateProfile} />
        </Media>

        <Photos className={style.photos} imagesIDs={imgID} />

        {locations && contact && amenities && socialIntegrations && (
          <>
            <Address {...getCommonProps('address')} locations={locations?.length ? locations : undefined} />

            <Contact {...getCommonProps('contact')} phone={contact?.phone} email={contact?.email} />

            <Description {...getCommonProps('description')} description={description} />

            <Amenities
              {...getCommonProps('amenities')}
              amenities={amenities?.length ? amenities.map(({ value }) => value) : undefined}
            />

            <Social
              {...getCommonProps('social')}
              socialIntegrations={socialIntegrations?.length ? socialIntegrations : undefined}
            />
          </>
        )}

        {alertVisible && (
          <Alert className={style.alert} onClose={() => setAlertVisible(false)}>
            You have unsaved changes
          </Alert>
        )}
      </div>
    </div>
  )
}

const style = {
  header: css`
    width: 100%;
    height: 130px;
    border-radius: 0 50px 0 0;

    @media ${MOBILE_VIEWPORT} {
      height: 165px;
      margin-top: -60px;
      border-radius: 0;
    }
  `,
  cards: css`
    @media ${MOBILE_VIEWPORT} {
      display: inline-block;
      width: 100%;
      margin-top: -40px;
      padding: 20px;
      padding-top: 0;
      border-radius: 0 45px 0 0;
      background-color: white;
    }
  `,
  status: css`
    margin-top: 18px;
  `,
  photos: css`
    @media ${MOBILE_VIEWPORT} {
      margin-top: 20px;
      box-shadow: none;
    }
  `,
  card: css`
    margin-top: 20px;

    @media ${MOBILE_VIEWPORT} {
      margin-top: 10px;
    }
  `,
  alert: css`
    z-index: 1;
    position: fixed;
    bottom: 16px;
    right: 10px;

    @media ${MOBILE_VIEWPORT} {
      bottom: 76px;
      right: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  `
}

export default Home
