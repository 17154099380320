import { useId, FC, HTMLAttributes } from 'react'
import { format } from 'date-fns'
import { useMedia } from 'frontend-essentials'
import { css, injectGlobal } from '@emotion/css'
import { MobileDatePicker, MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { DESKTOP_VIEWPORT } from 'styles/constants'
import Label from 'components/common/Label'
import Input from 'components/common/Input'

export type DatePickerProps = HTMLAttributes<HTMLDivElement> & {
  label?: string
  fromDate: Date
  toDate: Date
  onFromDateChange: (date: any) => void
  onToDateChange: (date: any) => void
}

const DatePicker: FC<DatePickerProps> = ({
  className,
  label,
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  ...otherProps
}) => {
  const id = useId()

  const { desktop } = useMedia({ desktop: DESKTOP_VIEWPORT })

  return (
    <div className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <div id={id} className="items-center" {...otherProps}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            className={style.datePicker}
            value={fromDate}
            renderInput={({ inputRef, inputProps }: any) => (
              <Input
                className={style.datePicker}
                inputClassName="cursor-pointer"
                ref={inputRef}
                {...inputProps}
                value={format(fromDate, `${desktop ? 'EEEE,  ' : ''}MMMM d`)}
              />
            )}
            onChange={onFromDateChange}
          />

          <MobileTimePicker
            ampm={false}
            className={style.timePicker}
            value={fromDate}
            renderInput={({ inputRef, inputProps }: any) => (
              <Input
                className={style.timePicker}
                inputClassName="text-center cursor-pointer"
                ref={inputRef}
                {...inputProps}
                value={format(fromDate, 'HH:mm')}
              />
            )}
            onChange={onFromDateChange}
          />

          <hr className={style.to} />

          <MobileTimePicker
            ampm={false}
            className={style.timePicker}
            value={toDate}
            renderInput={({ inputRef, inputProps }: any) => (
              <Input
                className={style.timePicker}
                inputClassName="text-center cursor-pointer"
                ref={inputRef}
                {...inputProps}
                value={format(toDate, 'HH:mm')}
              />
            )}
            onChange={onToDateChange}
          />
        </LocalizationProvider>
      </div>
    </div>
  )
}

injectGlobal`
  .PrivatePickersToolbar-dateTitleContainer {
    .MuiIconButton-sizeMedium {
      display: none;
    }
  }
`

const style = {
  datePicker: css`
    flex: 1;
    min-width: 0;
    margin-right: 15px;
  `,
  to: css`
    width: 6.5px;
    height: 1px;
    margin: 0 4px;
    border: none;
    background-color: #707070;
  `,
  timePicker: css`
    width: 75px;
  `
}

export default DatePicker
