import { css, cx } from '@emotion/css'
import type { FC, HTMLAttributes } from 'react'

import CloseIcon from 'images/close.svg'
import AlertIcon from 'images/alert.svg'

export type AlertProps = HTMLAttributes<HTMLDivElement> & {
  onSave: () => void
  onDiscard: () => void
  onClose: () => void
}

const Alert: FC<AlertProps> = ({ className, onSave, onDiscard, onClose, children, ...otherProps }) => {
  return (
    <div className={cx(style.wrapper, className)} onClick={onClose} {...otherProps}>
      <div className="items-center">
        <AlertIcon width="30px" />

        <h2 className={style.title}>{children}</h2>

        <CloseIcon className={style.close} />
      </div>
    </div>
  )
}

const style = {
  wrapper: css`
    position: relative;
    width: 350px;
    padding: 14px 16px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
    background-color: #fafafa;
  `,
  close: css`
    width: 12px;
    margin-left: auto;
    cursor: pointer;
  `,
  title: css`
    margin-left: 15px;
    font-size: 18px;
    font-weight: 400;
  `
}

export default Alert
