import { css } from '@emotion/css'
import { useGoogleLogin } from '@react-oauth/google'
import { axios } from 'frontend-essentials'
import useStore from 'hooks/useStore'
import React from 'react'
import { googleLogin as googleLoginEndpoint, googleOauth } from 'utils/endpoints.constants'
import googleIcon from 'images/google.png'
import { setUserFromResponse } from '../../utils/helpers/auth.helpers'

interface Props {
  style: {
    oAuth: any
    oAuthText: any
  }
}
const GoogleSignIn: React.FC<Props> = ({ style }) => {
  const { setUser } = useStore(state => state)

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await axios.get(googleOauth, {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`
        }
      })
      const data = await axios.post(googleLoginEndpoint, {
        email: userInfo.data.email,
        sub: userInfo.data.id.toString()
      })
      setUserFromResponse(data, setUser)
    },
    onError(errorResponse) {
      console.log(errorResponse)
    }
  })

  return (
    <button className={style.oAuth} disabled={false} onClick={() => googleLogin()}>
      <img width="24px" src={googleIcon} alt="google" />
      <span className={style.oAuthText}>Sign in with Google</span>
    </button>
  )
}

export default GoogleSignIn
