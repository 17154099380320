import { useId, FC } from 'react'
import { css, cx } from '@emotion/css'
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'

import Label from './Label'

export type SwitchProps = MuiSwitchProps & {
  label?: string
}

const Switch: FC<SwitchProps> = ({ className, label, ...otherProps }) => {
  const id = useId()

  return (
    <div className={cx(style.wrapper, className)}>
      <Label className={style.label} htmlFor={id}>
        {label}
      </Label>

      <MuiSwitch className={style.checkbox} id={id} {...otherProps} />
    </div>
  )
}

const style = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,
  label: css`
    margin: 0;
    margin-top: -2.5px;
  `,
  checkbox: css`
    &.MuiSwitch-root {
      .MuiSwitch-track {
        background-color: #221f1f;
      }

      .MuiSwitch-thumb {
        color: #f1f1f1;
      }

      .Mui-checked {
        + .MuiSwitch-track {
          background-color: #3c49e8;
        }

        .MuiSwitch-thumb {
          color: #3c49e8;
        }
      }
    }
  `
}

export default Switch
