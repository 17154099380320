import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTop = (user: any) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [user, pathname])
}

export default useScrollToTop
