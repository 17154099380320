import { UserResponse } from 'hooks/useStore'

export const setUserFromResponse = (responseData: any, setUser: (obj: UserResponse) => void) => {
  const response = responseData.data.response
  setUser({
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
    user: {
      email: response.user.email,
      isServiceProvider: response.user.is_service_provider,
      isStaff: response.user.is_staff,
      isVerified: response.user.is_verified,
      userId: response.user.user_id
    },
    rememberMe: true
  })
}
